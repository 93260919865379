export default {
  personalInfo: {
    registrationInfo: '注册信息',
    contactInfo: '联系信息',
    externalBind: '外部绑定',
    notBind: '未绑定',
    bound: '已绑定',
    leaveAcademyTip: '你即将离开当前页面，并跳转到第三方页面',
    unbindAccount: '解绑 {name} 账号',
    afterUnbindTip: '解绑后，您将不能使用 {name} 进行登录',
    bindSuccess: '账号绑定成功',
    loadingText: '加载中，请稍候',
    tieToAccount: '绑定到 {name}',
    accountBind: '账号绑定',
    moreSetting: '更多设置',
    registionToLogin: '注册信息用于您的登录',
  },
}
