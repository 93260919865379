export default {
  loginInfo: {
    findRelatedAcount: 'Trouver le compte {num} qui pourraient être le vôtre',
    existAccount: 'Compte existant',
    updateExistAccount: 'Souhaitez-vous mettre à jour le compte existant?',
    updateRegisInfoExistAccount: 'Mettre à jour ces informations d\'inscription pour le compte existant',
    MobileOrEmail: 'téléphone portable ou courriel',
    yes: 'Oui',
    no: 'Non',
    success: 'réussi',
    mobile: 'Téléphone portable',
    email: 'E-mail',
    next: 'L\'étape suivante',
    verifyTitle: 'Nous vous avons envoyé un code de vérification',
    verifyContent: 'Veuillez le saisir ci-dessous pour vérifier',
  },
}
