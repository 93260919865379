<template>
  <div class="get-notified-wrap" v-lazy-background lazy-background="./assets/bg-web.png">
    <div class="get-notified-container">
      <div class="book-intro">
        <div class="title font-bold text-overflow-ellipsis-4" v-html="title" />
        <div
          class="description font-medium text-overflow-ellipsis-3"
          v-html="intro"
        />
      </div>
      <div class="book-form">
        <div class="form-wrapper">
          <el-form
            ref="formData"
            :model="formData"
            :rules="rules"
            :hide-required-asterisk="true"
            class="uk-selected-form invisible-lable"
          >
            <div class="item-row">
              <div class="item-col">
                <el-form-item
                  prop="firstName"
                  :class="{ 'visible-lable': formData.firstName !== '' }"
                >
                  <el-input
                    v-model="formData.firstName"
                    :placeholder="firstName"
                  />
                </el-form-item>
              </div>
              <div class="item-col">
                <el-form-item
                  prop="lastName"
                  :class="{ 'visible-lable': formData.lastName !== '' }"
                >
                  <el-input
                    v-model="formData.lastName"
                    :placeholder="lastName"
                  />
                </el-form-item>
              </div>
            </div>

            <div class="item-row">
              <div class="item-col">
                <el-form-item
                  prop="email"
                  :class="{ 'visible-lable': formData.email !== '' }"
                >
                  <el-input v-model="formData.email" :placeholder="emailName" />
                </el-form-item>
              </div>
              <div class="item-col more">
                <el-form-item
                  prop="gradeId"
                  :class="{ 'visible-lable': formData.gradeId !== '' }"
                >
                  <el-select
                    v-model="formData.gradeId"
                    :placeholder="gradeName"
                    popper-class="phone-area-code-dropdown normal-grace-dropdown text-left"
                    @change="handleGradeChange"
                  >
                    <el-option
                      v-for="(item, index) in gradeList"
                      :key="index"
                      :label="item.name"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </div>
            </div>

            <el-checkbox
              v-model="formData.subStatus"
              class="uk-policy-agree-checkbox"
            >
              {{ agreementNotice }}
            </el-checkbox>

            <div class="button-wrapper">
              <el-button
                class="button uk-button-normal"
                :loading="loading"
                @click="handleSubmit"
              >
                {{ submitName }}
              </el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixin from "./mixin";
import trackReportMixin from "../Common/CommonMixin/trackReport";
import { oneApi } from "config/api";
import dataReport from "common/dataReport";

export default {
  mixins: [mixin, trackReportMixin],
  mounted() {
    this.init();
  },
  methods: {
    // 处理提交按钮操作
    handleSubmit() {
      this.$refs.formData.validate((valid) => {
        if (!valid) {
          return false;
        }
        this.save(() =>
          this.$ElMessage({
            type: "success",
            message: this.successNotice,
          })
        );

        return true;
      });
    },

    // 数据保存
    async save(callback) {
      const data = {
        firstName: this.formData.firstName,
        lastName: this.formData.lastName,
        email: this.formData.email,
        gradeId: this.formData.gradeId,
        subStatus: this.formData.subStatus ? 1 : 0,
        channelId: 1,
        sourceCode: "website",
        mediaCode: "pc_lead",
      };
      // 打开Loading
      this.loading = true;
      // 保存数据接口
      const res = await this.$axios.post(oneApi.addSaleslead, data, {
        rewritePostBody: true,
      });
      // 关闭Loading
      this.loading = false;

      // 业务异常阻断
      if (Number(res.code) !== 0) {
        return this.$ElMessage({
          message: res.msg,
          type: "warning",
        });
      }

      // 埋点上报
      this.buttonTrackReport({
        buttonName: this.submitName,
      });
      window.dataLayer.push({
        event: "generatelead_gtm",
      });
      // 留资组件第三方埋点上报
      dataReport.launchReport({
        fbqOptions: {
          name: "Lead",
        },
        ttqOptions: {
          name: "SubmitForm",
        },
      });

      // 清空表单
      this.$refs.formData.resetFields();

      // 执行回调
      if (callback) {
        callback();
      }
      return res;
    },

    // 处理年级选择事件
    handleGradeChange(val) {
      const gradeItem = this.gradeList.filter((item) => item.value === val);
      this.formData.gradeName = gradeItem[0].name;
    },
  },
};
</script>
<style lang="scss" scoped>
.get-notified-wrap {
  margin: 0 auto;
  max-width: 1240px;
  height: 350px;
  background-color: #ffb541;
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 16px;

  .get-notified-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  .book-intro {
    width: 396px;
    white-space: pre-line;
    margin: 0 0 0 40px;

    .title {
      margin-bottom: 20px;
      color: #fff;
      font-size: 36px;
      line-height: 36px;
      word-spacing: -3px;
      letter-spacing: -0.25px;
    }

    .description {
      font-size: 16px;
      color: #fff;
      line-height: 18px;
      letter-spacing: -0.3px;
    }
  }

  .book-form {
    background: #fff;
    box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    margin-right: 40px;

    .form-wrapper {
      margin: 30px 30px 20px 30px;
    }

    .item-row {
      display: flex;
    }

    /deep/.el-form-item {
      border: none;
      background: none;

      .el-select {
        display: inline-block;
        width: 100%;

        .el-select__tags {
          height: 28px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .el-form-item__content {
        background: rgba(242, 242, 242, 1);
        border-radius: 30px;
        // padding: 13px 20px;
        width: 320px;
      }

      .el-input__inner {
        border: none;
        background: none;
        font-weight: 500;
      }

      .el-input-group__prepend {
        border: none;
        background: none;
      }

      .login-form-phone-code-select {
        padding-right: 10px;
        width: 85px;
        color: var(--txt-color-lv1);

        .el-select__caret {
          color: var(--txt-color-lv1);
        }
      }
    }

    /deep/.long-item {
      margin-left: 18px;

      .el-form-item__content {
        width: 360px;
      }
    }

    .item-col {
      &:first-child {
        padding-right: 9px;
      }

      &:last-child {
        padding-left: 9px;
      }

      &.more {
        /deep/.el-form-item .el-form-item__content {
          width: 320px;
        }
      }

      /deep/.el-form-item__error {
        left: 20px;
      }
    }

    .button-wrapper {
      margin-top: 20px;
      text-align: center;

      .uk-button-normal {
        height: 48px;
        display: inline-flex;
        line-height: 48px;
        width: 160px;
      }
    }
  }
}

.phone-area-code-dropdown {
  overflow: visible;

  .el-scrollbar {
    height: inherit;
  }

  .phone-area-code-option {
    width: 260px;

    .area-code {
      display: inline-block;
      margin-right: 10px;
      width: 60px;
    }
  }
}
</style>
