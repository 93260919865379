export default {
  personalInfo: {
    registrationInfo: '登録情報',
    contactInfo: '連絡先情報',
    externalBind: '外部連携',
    notBind: '連携していません',
    bound: '連携済み',
    leaveAcademyTip: '今のページからほかのページに移動することになります',
    unbindAccount: 'アカウント{name}の連携を解除する',
    afterUnbindTip: '解除後、アカウント{name}でログインできなくなります',
    bindSuccess: 'アカウント連携成功',
    loadingText: '読み込み中、お待ちください',
    tieToAccount: '{name}に連携',
    accountBind: 'アカウントの連携',
    moreSetting: 'その他の設定',
    registionToLogin: '登録情報はログインに使用されます',
  },
}
