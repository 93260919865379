<template>
  <el-input v-model="code" class="login-input code-input new-login-font" :class="`code-${$store.state.locale}`" :placeholder="$t('login.login.loginForm.captcha.placeholder')" @blur="captchaBlurHandler" @focus="inputFocusHandler">
    <template slot="append">
      <div class="captcha-append-container">
        <span v-if="startCountdown" class="resend-countdown">{{ resendCountdown }}s</span>
        <span v-else class="btn-send-captcha font-medium" :class="{'disabled': !isSendOtpBtnAvalible}" @click="sendCodeHanlder">{{ sendOtpBtnText }}</span>
      </div>
    </template>
  </el-input>
</template>
<script>
import Cookies from 'js-cookie';
import { filterBlank } from 'common/utils.js';
import { oneApi } from 'config/api';
// import gRecaptcha from '@thinkacademy/vitas-utils/gRecaptcha';
/* eslint-disable vue/require-default-prop */

export default {
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'email',
    },
    phoneOrEmail: {
      type: [String, Number],
      default: '',
    },
    areaCode: {
      type: [String, Number],
      default: '',
    },
    beforeSend: Function,
    sendError: Function,
    sendSuccess: {
      type: Function,
      default: () => {},
    },
    cookieName: String,
    scene: {
      type: Number,
      default: 1,
    },
    canClick: {
      type: Boolean,
      default: true,
    },
    isTop: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      startCountdown: false,
      sendOtpBtnText: this.$t('login.login.otpSendBtn.normal'),
      resendCountdown: 0,
      timer: null,
      code: '',
      sendClick: false,
      lastPhoneOrEmail: '',
    }
  },
  computed: {
    isSendOtpBtnAvalible() {
      let canSend = false
      if (this.scene === 2) {
        canSend = (!this.startCountdown && !this.sendClick);
      } else {
        canSend = (!this.startCountdown && this.canClick && !this.sendClick);
      }
      return canSend
    },
  },
  watch: {
    value(val) {
      this.code = val
    },
    code(val) {
      this.$emit('input', val)
    },
  },
  methods: {
    init() {
      clearInterval(this.timer);
      this.startCountdown = false;
      this.sendOtpBtnText = this.$t('login.login.otpSendBtn.normal');
      this.resendCountdown = 0;
      this.timer = null;
      this.code = '';
      this.sendClick = false;
    },
    sendCodeHanlder() {
      const sendFunc = () => {
        this.sendCaptcha()
      };
      if (!this.beforeSend) {
        sendFunc();
      } else if (typeof this.beforeSend === 'function') {
        this.beforeSend(sendFunc)
      }
    },
    /**
     * 发送手机验证码
     */
    async sendCaptcha() {
      console.log(this.phoneOrEmail, this.isSendOtpBtnAvalible, 'phoneOrEmail sendCaptcha');
      // if (!this.isSendOtpBtnAvalible) {
      //   return
      // }
      // 手机/邮箱获取验证码点击埋点
      this.Sensors.track('code_click', {
        type: this.isTop,
        account_type: this.type,
      })
      this.sendClick = true;
      this.resendCountdown = Cookies.get(this.cookieName) ? parseInt(Number(Cookies.get(this.cookieName)) - (new Date().getTime() / 1000), 10) : 0
      console.log(this.resendCountdown, 'resendCountdown', this.type, this.lastPhoneOrEmail);
      if (this.resendCountdown !== 0 && this.phoneOrEmail === this.lastPhoneOrEmail) {
        // 开始倒计时
        this.sendClick = false;
        this.setIntervalFunc()
        this.sendSuccess();
        return
      }
      let data = {}
      if (this.type === 'phone') {
        data = {
          scene: this.scene,
          type: 0, // 0手机号
        }
        if (this.scene !== 2) {
          data.contactInfo = this.phoneOrEmail.trim()
          data.countryCallingCode = this.areaCode.trim()
        }
      } else {
        data = {
          scene: this.scene,
          type: 2, // 2邮箱
        }
        if (this.scene !== 2) {
          data.contactInfo = this.phoneOrEmail.trim()
        }
      }
      // const { verifyKey } = process.env;
      // const token = await gRecaptcha.getToken.call(this, verifyKey)
      // this.$store.commit('verifyToken/SEND_GRC_TOKEN', token)
      this.$store.commit('verifyToken/SEND_GRC_ACTION', 'sendVerification')
      const res = await this.$axios.post(oneApi.sendLoginMobileCode, data, { rewritePostBody: true });
      this.sendClick = false
      if (Number(res.code) !== 0) {
        this.$ElMessage({
          type: 'error',
          message: res.msg,
        });
        if (typeof this.sendError === 'function') {
          this.sendError()
        }
        return;
      }
      this.sendSuccess();
      // 设置cookie倒计时数字
      this.resendCountdown = 60
      this.setCookie()
      // 开始倒计时
      this.setIntervalFunc()
      // this.setSendCodeBtn();
      // 更新 lastPhoneOrEmail
      this.lastPhoneOrEmail = this.phoneOrEmail;
    },
    setCookie() {
      const seconds = new Date().getTime() / 1000 + 60;
      const expires = new Date(new Date() * 1 + 60 * 1000);
      Cookies.set(this.cookieName, seconds, { expires })
    },
    // 倒计时
    setIntervalFunc() {
      this.sendOtpBtnText = this.$t('login.login.otpSendBtn.reResend');
      this.startCountdown = true;
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.resendCountdown -= 1;
        if (this.resendCountdown <= 0) {
          this.startCountdown = false;
          clearInterval(this.timer)
          this.resendCountdown = 0;
        }
      }, 1000)
    },
    captchaBlurHandler() {
      this.value = filterBlank(this.value);
    },
    inputFocusHandler() {
      this.$emit('input-focus')
    },
  },
}
</script>
<style lang="scss" scoped>
.code-input {
  height: 100%;

  /deep/ .el-input__inner {
    height: 100%;
  }

  .captcha-append-container {
    width: 100%;
    height: 100%;
    font-size: 16px;
    color: var(--txt-color-link);
    line-height: 50px;
    text-align: center;

    .btn-send-captcha {
      cursor: pointer;

      &.disabled {
        color: var(--txt-color-lv2);
        cursor: not-allowed;
      }
    }
  }
}

.code-am,
.code-ae {
  .captcha-append-container {
    color: #2cc0b7;
  }
}
</style>
