export default {
  personalInfo: {
    registrationInfo: '등록 정보',
    contactInfo: '연락처 정보',
    externalBind: '외부 바인딩',
    notBind: '바인딩 안함',
    bound: '바인딩됨',
    leaveAcademyTip: '현재 페이지를 떠나, 다른 페이지로 이동합니다',
    unbindAccount: '{name} 계정 해제',
    afterUnbindTip: '해제 후에는 {name}를 사용하여 로그인할 수 없습니다',
    bindSuccess: '계정 연동 성공',
    loadingText: '로딩 중, 잠시만 기다려 주세요',
    tieToAccount: '{name}에 연동',
    accountBind: '계정 연동',
    moreSetting: '추가 설정',
    registionToLogin: '등록 정보는 로그인에 사용됩니다',
  },
}
