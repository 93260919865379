export default {
  loginInfo: {
    findRelatedAcount: '找到{num}個可能是您的賬號',
    existAccount: '已存在的賬號',
    updateExistAccount: '您是否要更新現有賬戶？',
    updateRegisInfoExistAccount: '將此註冊信息更新到現有賬戶',
    MobileOrEmail: '手機號或郵箱',
    yes: '是',
    no: '否',
    success: '賬號更新成功',
    mobile: '手機',
    email: '郵箱',
    next: '下一步',
    verifyTitle: '我們向您發送了一個驗證碼',
    verifyContent: '請在下方輸入以進行驗證',
  },
}
