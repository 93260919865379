export default {
  personalInfo: {
    registrationInfo: 'Informations sur l\'inscription',
    contactInfo: 'Informations de Contact',
    externalBind: 'Liaison externe',
    notBind: 'Compte non lié',
    bound: 'Compte lié',
    leaveAcademyTip: 'Vous êtes sur le point de quitter Think Academy et de passer à une autre page',
    unbindAccount: 'Déconnexion du compte {name}',
    afterUnbindTip: 'Après la dissociation, vous ne pourrez plus vous connecter avec votre compte {name}',
    bindSuccess: 'Liaison de compte réussie',
    loadingText: 'Chargement, veuillez patienter',
    tieToAccount: 'Lier à {name}',
    accountBind: 'Liaison de Compte',
    moreSetting: 'plus de paramètres',
    registionToLogin: 'Les informations d\'inscription sont utilisées pour votre connexion',
  },
}
