export default {
  pageTitle: '下载Think Academy客户端',
  studentApp: '学生端',
  studentInfo: '沉浸式互动线上课堂，激发学习兴趣',
  parentApp: '家长端',
  parentInfo: '全面了解孩子学习进度，实时获得孩子学情回响',
  deviceTitle: '设备要求',
  windowsRequirement: {
    title: 'Windows要求',
    info1: '操作系统： Windows 7及以上版本',
    info2: '最低配置：CPU 4 核，4GB RAM',
    info3: '设备：不支持使用设备Chromebook和Surface Book',
  },
  macRequirement: {
    title: 'Mac要求',
    info1: '操作系统：MacOS 10.12及以上',
    info2: '最低配置：CPU 4 核，4GB RAM',
  },
  ipadRequirement: {
    title: 'iPad要求',
    info1: '操作系统：iOS 10.0及以上版本',
    info2: '设备：iPad 5、iPad Air 3、iPad mini 5和所有更新型号',
  },
  iphoneRequirement: {
    title: 'iPhone要求',
    info1: '操作系统：iOS 10.0及以上版本',
    info2: '设备：iPhone7/Plus及所有更新型号',
  },
  androidRequirement: {
    title: 'Android要求',
    info1: '操作系统：Android 6.0及以上',
    info2: '最低配置： (Tablet)2.0 GHz处理器速度，4GB RAM',
  },
  parentThinkAcademy: 'Think&nbsp;Academy家长端',
  studentThinkAcademy: 'Think&nbsp;Academy学生端',
  download: '下载',
  studentDownloadInfo: {
    title: '沉浸式互動網上課程以激發興趣',
    step1: '步驟 1',
    step1Info: '為確保您的孩子擁有良好的課堂體驗，我們建議使用電腦、iPad 或 Android 平板下載學生應用程序：Think Academy Student。如果需要使用手機，請點擊下載按鈕。',
    step2: '步驟 2',
    step2Info: '打開 “Think Academy Student” 並進入教室。請使用您 Think Academy Student 帳號登錄學生應用程序，以確認您選擇了正確的孩子。孩子們可以在課程開始前15分鐘進入教室。',
  },
}
