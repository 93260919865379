/**
 * 分校地址重定向
 */

export default function ({ store, req, redirect }) {
  // 客户端环境不执行
  if (process.client) return

  const { locale } = store.state
  // 美分访问地址重定向
  if (locale === 'us') {
    // let queryParams = req.ctx.query ? new URLSearchParams(req.ctx.query).toString() : '';
    // queryParams = queryParams ? `?${queryParams}` : '';
    if (req.ctx.path === '/campuses/palo-alto') {
      redirect(302, '/campuses/los-altos')
    } else if (req.ctx.path === '/campuses/belmont' || req.ctx.path === '/campuses/fremont') {
      redirect(302, '/campuses/cupertino')
    } else if (req.ctx.path === '/grades-prek-2') {
      redirect(302, '/lower-elementary')
    } else if (req.ctx.path === '/grades-3-5') {
      redirect(302, '/upper-elementary')
    } else if (req.ctx.path === '/sat') {
      redirect(302, '/middle-school')
    } else if (req.ctx.path === '/thinkmatrix' || req.ctx.path === '/campuses' || req.ctx.path === '/math-competitions' || req.ctx.path === '/resource' || req.ctx.path === '/practice' || req.ctx.path === '/special-event') {
      redirect(302, '/')
    } else if (req.ctx.path === '/about-us') {
      redirect(302, '/aboutus')
    } else if (req.ctx.path === '/ap') {
      redirect(302, '/middle-school')
    } else if (req.ctx.path === '/magic-math') {
      redirect(302, '/lower-elementary')
    } else if (req.ctx.path === '/world-think-cup') {
      redirect(302, '/upper-elementary')
    } else if (req.ctx.path === '/math-reward-challenge') {
      redirect(302, '/middle-school')
    } else if (req.ctx.path === '/trial-classes') {
      redirect(302, '/evaluation')
    }
  }
}
