export default {
  refund: {
    payment: '支付',
    refundTuition: '可退',
    refundMaterial: '可退的學習資料',
    refundMaterialTitle: '可退的學習資料',
    refundMaterialTips: '只有未發貨的學習資料支持申請退費，若您有任何問題，請聯繫我們的客服：{x}',
    attachedGood: '附加商品',
  },
  preclass: '課前準備',
  preclassTips1: '請下載',
  preclassTips2: '瞭解課前準備',
  safetyTips: '我們的支付服務由國際認證的安全支付機構提供',
  activation: 'activation',
  toBeActivation: 'To be activated',
  shoppingCart: {
    myCart: '我的購物車',
    totalItems: '總計{x}項',
    deleteItem: '刪除寶貝',
    cancelText: '取消',
    confirmDel: '確認刪除',
    deleteText: '刪除',
    couponsInvalid: '不可用優惠券',
    payMethod: '這種付款方式是',
    autoDeduction: '自動扣款',
    cartDetails: '購物車詳情',
    addToCart: '加入購物車',
    noData: '暫無數據',
    confirmDelete: '確定從購物車中刪除嗎？',
    productName: '商品名稱',
    amtPayable: '應付金額',
    discountAmt: '優惠金額',
    amtPaid: '實付金額',
    offerDetails: '優惠詳情',
    changeOffer: '變更優惠',
    additional: '附加',
    cart: '購物車',
    admissionNotice: '您的孩子需要通過入學診斷，然後才能報名參加這個課程，只有 {x} 次機會',
    cannotCreateOrder: '當前商品無法創建訂單',
    opFrequent: '操作過於頻繁',
    qtyModifiedSuccess: '數量修改成功',
    deleteSuccess: '刪除操作成功',
    valExceed: '數值不可超過{x}',
    valLess: '數值不可小於{x}',
    addedSuccess: '添加成功',
    promoNotice: '目前僅對部分用戶提供促銷活動，請在結帳時確認最終價格',
    addedToCartSuccess: '加入購物車成功',
    combinedDiscount: '合併折扣',
    mailingNotice: '您所購買的課程（商品）含有郵寄物，請確認您的地址：',
    refundNotice: '您所選的退款課程為聯報商品，退款會關閉聯報中其他課程的自動繳費功能，請確認是否繼續？',
  },
  selectedClassDiffers: '您選擇的班級與原班{x}不同，請確認您要調課嗎？',
  selectedClass: '您選擇的班級',
  originalClass: '您的原班',
  grade: '年級',
  semester: '學期',
  subject: '學科',
  classLevel: '班次級別',
  teachingMethod: '授課方式',
  confirmClassChange: '是否確認調課',
}
