<template>
  <div class="login-account-box phone-email-input-container" :data-placeholder="$t('login.loginInfo.MobileOrEmail')" :class="[{['phone-' + $store.state.locale]: true}, currentFocus]">
    <el-input
      ref="phoneInput"
      v-model="inputValue"
      class="login-input account-input phone-box"
      :class="[{ 'is-email-box': isEmail }, currentStatus]"
      :clearable="true"
      :maxlength="isEmail ? 45 : 16"
      @blur="inputBlurHandler"
      @focus="inputFocusHandler"
    >
      <el-select
        id="update_phone_select"
        slot="prepend"
        v-model="phoneAreaCode"
        :popper-append-to-body="false"
        popper-class="phone-area-code-dropdown-n normal-grace-dropdown text-left"
        class="login-form-phone-code-select normal-grace-dropdown font-medium phone-code-special"
        value-key="countryCode"
        @focus="selectFocusHandler"
        @blur="selectBlurHandler"
      >
        <el-option
          v-for="code in phoneCodeList"
          :key="code.countryCode"
          class="phone-area-code-option"
          :label="`+${code.value}`"
          :value="code"
        >
          <div class="select-line">
            <div class="area-name">
              {{ code.countryName }}
            </div>
            <div class="area-code">
              +{{ code.value }}
            </div>
          </div>
        </el-option>
      </el-select>
    </el-input>
  </div>
</template>
<script>
import { filterBlank } from 'common/utils.js';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    lastModified: {
      type: Object,
      default: () => ({}),
    },
    areaCode: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    const { locale } = this.$store.state;
    return {
      locale,
      inputValue: '',
      phoneAreaCode: {},
      phoneCodeList: this.$store.state.phoneCodeList,
      currentStatus: '',
      currentFocus: '',
      isEmail: true, // 默认是邮箱
    };
  },
  watch: {
    value(val) {
      this.inputValue = val
    },
    inputValue: {
      immediate: true,
      handler(val) {
        this.$emit('input', val)
        this.isEmail = !/^\d+$/.test(val);
        this.$emit('update-type', this.isEmail ? 'email' : 'phone');
      },
    },
    phoneAreaCode: {
      handler(val) {
        this.$emit('areaChange', val);
      },
      immediate: true,
      deep: true,
    },
    lastModified() {
      if (Object.keys(this.lastModified).length && this.lastModified.type === 'phone') {
        this.inputValue = this.lastModified.accountName;
        const a = this.phoneCodeList.filter(
          (item) => item.value === this.lastModified.countryCallingCode,
        )[0];
        this.$nextTick(() => {
          this.phoneAreaCode = a;
        });
      } else if (Object.keys(this.lastModified).length && this.lastModified.type === 'email') {
        this.inputValue = this.lastModified.accountName
      }

      if (this.inputValue) {
        this.currentFocus = 'input-focus';
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      if (!this.phoneCodeList.length) {
        await this.$store.dispatch('requeryBasicData', this.$axios);
        this.phoneCodeList = [...this.$store.state.phoneCodeList];
      }
      // 初始化手机区号对象
      let initPhoneAreaCode = '';
      if (Object.keys(this.lastModified).length > 0) {
        this.inputValue = this.lastModified.accountName;
        if (typeof this.lastModified.countryCallingCode === 'string') {
          // eslint-disable-next-line prefer-destructuring
          initPhoneAreaCode = this.phoneCodeList.filter(
            (item) => item.value === this.lastModified.countryCallingCode,
          )[0];
        } else {
          initPhoneAreaCode = this.lastModified.countryCallingCode;
        }
      } else {
        // 初始化手机区号对象
        initPhoneAreaCode = this.$store.state.defaultPhoneCode || this.phoneCodeList[0];
      }
      this.phoneAreaCode = initPhoneAreaCode;

      if (this.inputValue) {
        this.currentFocus = 'input-focus';
      }
    },
    inputBlurHandler() {
      this.value = filterBlank(this.value);
      if (!this.inputValue) {
        this.currentFocus = '';
      }
    },
    inputFocusHandler() {
      this.$emit('input-focus');
      this.currentFocus = 'input-focus';
    },
    selectFocusHandler() {
      this.currentStatus = 'is-active';
    },
    selectBlurHandler() {
      this.currentStatus = '';
    },
  },
};
</script>
<style lang="scss" scoped>

.phone-email-input-container::after {
  content: attr(data-placeholder);
  display: block;
  position: absolute;
  top: 36px;
  left: 10px;
  padding: 0 4px;
  font-size: 16px;
  font-family: 'Montserrat-Regular', 'Montserrat', sans-serif;
  font-weight: 400;
  color: #E6E6E6;
  line-height: 18px;
  pointer-events: none;
  transition: all .2s cubic-bezier(.4,0,.2,1), max-width .324s cubic-bezier(.4,0,.2,1);
  z-index: 1;
}

.input-focus::after {
  transform: translateY(-22px);
  font-family: 'Montserrat-Regular', 'Montserrat', sans-serif;
  font-weight: 400;
  color: rgba(34, 34, 34, 0.6);
  line-height: 14px;
  font-size: 12px;
  background-color: #fff;
}

.login-form-phone-code-select {
  height: 100%;

  .phone-area-code-dropdown {
    width: 392px;
    height: 260px;
    margin-top: 10px !important;
    margin-left: -10px;
    background: #fff;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
    border-radius: 4px;

    .el-scrollbar {
      height: inherit;
    }

    .phone-area-code-option {
      position: relative;
      width: 100%;
      height: 36px;
      margin: 10px 0;
      padding-left: 10px;
      padding-right: 20px;
      line-height: 36px;

      .select-line {
        height: 100%;
        padding: 0 10px;
        border-radius: 2px;
        font-size: 14px;
        font-weight: 400;
        color: var(--txt-color-lv2);
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &:hover {
        background: transparent !important;

        .select-line {
          color: var(--txt-color-link);
        }
      }

      &.selected {
        background: transparent !important;

        .select-line {
          background: var(--txt-color-link);
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }

  .el-input {
    height: 100%;
  }

  .el-input__suffix {
    width: 100%;
    right: 0;
    color: var(--txt-color-lv2);

    .el-input__suffix-inner {
      display: inline-block;
      margin-top: unset;
      width: 100%;
      height: 100%;
      background: unset;

      .el-select__caret.el-input__icon {
        width: 100%;
        height: 100%;
        color: var(--txt-color-lv2);

        &::before {
          content: "\e683";
          font-family: "iconfont", sans-serif;
          font-size: 9px;
          display: inline-block;
          transform: rotate(180deg) scale(0.5);
          position: absolute;
          top: 4px;
          right: 38px;
        }

        &.is-reverse {
          transform: rotateZ(180deg);
        }
      }
    }
  }

  #update_phone_select {
    height: 100%;
    padding: 0;
    background: #fff;
    color: var(--txt-color-lv2);
    font-weight: 400;
  }

  .is-focus {
    #update_phone_select,
    .el-input__suffix,
    .el-input__suffix .el-input__suffix-inner .el-select__caret.el-input__icon {
      color: var(--txt-color-link) !important;
    }
  }
}
.is-hidden {
  display: none;
}

.phone-am,
.phone-ae {
  .phone-area-code-dropdown {
    .phone-area-code-option {
      &:hover {
        .select-line {
          color: #2cc0b7;
        }
      }

      &.selected {
        .select-line {
          background: #2cc0b7;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.phone-code-special {
  .el-input__inner {
    padding-left: 0 !important;
  }

  .el-input__suffix {
    right: -15px !important;
  }
}

.phone-area-code-dropdown-n {
  width: 392px;
  margin-left: -10px;

  .select-line {
    display: flex;
    justify-content: space-between;
  }
}
</style>
