export default {
  pageTitle: 'Télécharger Think Academy Apps',
  studentApp: 'App étudiante',
  studentInfo: 'Des cours en ligne immersifs et interactifs pour stimuler l\'intérêt',
  parentApp: 'Application parentale',
  parentInfo: 'Votre assistant pratique pour des mises à jour en temps réel sur les progrès d\'apprentissage de votre enfant',
  deviceTitle: 'Configuration requise pour l’appareil',
  windowsRequirement: {
    title: 'Configuration requise pour Windows',
    info1: 'Système d\'exploitation : Windows 7 et supérieur',
    info2: 'Configurations minimales : CPU 4 Cores, 4Go RAM',
    info3: 'Appareil : Chromebook et Surface Book ne sont pas pris en charge',
  },
  macRequirement: {
    title: 'Configuration requise pour Mac',
    info1: 'Système d\'exploitation : MacOS 10.12 et supérieur',
    info2: 'Configurations minimales : CPU 4 Cores, 4Go RAM',
  },
  ipadRequirement: {
    title: 'Exigences pour l\'iPad',
    info1: 'Système d\'exploitation : iOS 10.0 et supérieur',
    info2: 'Appareil : iPad 5, iPad Air 3, iPad mini 5, et tous les modèles plus récents',
  },
  iphoneRequirement: {
    title: 'Exigences pour l\'iPhone',
    info1: 'Système d\'exploitation : iOS 10.0 et supérieur',
    info2: 'Appareil : iPhone7/Plus et tous les modèles plus récents',
  },
  androidRequirement: {
    title: 'Exigences pour Android',
    info1: 'Système d\'exploitation : Android 6.0 et supérieur',
    info2: 'Configurations minimales (tablette) : Processeur 2,0 GHz, 4 Go de RAM',
  },
  parentThinkAcademy: 'Think Academy parentale',
  studentThinkAcademy: 'Think Academy étudiante',
  download: 'Télécharger',
  studentDownloadInfo: {
    title: 'Application pour les élèves',
    step1: 'Étape 1',
    step1Info: "Pour assurer une bonne expérience en classe à votre enfant, nous vous recommandons de télécharger l'application pour élèves : Think Academy Student sur un ordinateur, un iPad ou une tablette Android. Si vous devez utiliser un téléphone portable, veuillez cliquer sur le bouton de téléchargement.",
    step2: 'Étape 2',
    step2Info: 'Ouvrez "Think Academy Student" et entrez dans la salle de classe. Veuillez vous connecter à l\'application avec votre compte Think Academy Student pour confirmer que vous avez sélectionné le compte. Les enfants peuvent entrer dans la salle de classe 15 minutes avant le début du cours.',
  },
}
