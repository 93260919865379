export default {
  pageTitle: 'Think Academy 앱 다운로드',
  studentApp: '학생 앱',
  studentInfo: '흥미 유발을 위한 몰입형 대화식 온라인 수업',
  parentApp: '부모 앱',
  parentInfo: '자녀의 학습 진행 상황에 대한 실시간 업데이트를 제공하는 편리한 도우미',
  deviceTitle: '기기 요구 사항',
  windowsRequirement: {
    title: 'Windows 요구 사항',
    info1: '운영 체제: Windows 7 이상',
    info2: '최소 구성: CPU 4 코어, 4GB RAM',
    info3: '기기: Chromebook 및 Surface Book은 지원되지 않습니다',
  },
  macRequirement: {
    title: 'Mac 요구 사항',
    info1: '운영 체제: MacOS 10.12 이상',
    info2: '최소 구성: CPU 4 코어, 4GB RAM',
  },
  ipadRequirement: {
    title: 'iPad 요구 사항',
    info1: '운영 체제: iOS 10.0 이상',
    info2: '기기: iPad 5, iPad Air 3, iPad mini 5 및 그 이상의 모델',
  },
  iphoneRequirement: {
    title: 'iPhone 요구 사항',
    info1: '운영 체제: iOS 10.0 이상',
    info2: '기기: iPhone7/Plus 및 그 이상의 모델',
  },
  androidRequirement: {
    title: 'Android 요구 사항',
    info1: '운영 체제: Android 6.0 이상',
    info2: '최소 구성 (태블릿): 2.0 GHz 프로세서 속도, 4GB RAM',
  },
  parentThinkAcademy: 'Think Academy 부모',
  studentThinkAcademy: 'Think Academy 학생',
  download: '다운로드',
  studentDownloadInfo: {
    title: '흥미 유발을 위한 몰입형 대화식 온라인 수업',
    step1: '절차1',
    step1Info: '아이가 좋은 수업 경험을 할 수 있도록 컴퓨터, 아이패드 또는 안드로이드 태블릿을 사용하여 학생용 앱인 Think Academy Student를 다운로드하는 것을 추천드립니다. 휴대폰 사용이 필요하시면 다운로드 버튼을 누르세요.',
    step2: '절차2',
    step2Info: "'Think Academy Student' 앱을 열고 교실로 들어갑니다. 정확한 아이를 선택했는지 확인해야 하기에 고객님의 Think Academy Student 계정으로 학생 앱에 로그인하세요. 아이들은 수업 시작 15분 전에 교실에 들어갈 수 있습니다.",
  },
}
