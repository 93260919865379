<template>
  <div class="side-navigation-bar-wrapper">
    <div class="user-card-container font-medium">
      <div class="operate-btn-group">
        <nuxt-link class="icon-btn icon-setting" to="/settings">
          <i class="icon-setting" />
        </nuxt-link>
      </div>
      <div>
        <div class="user-avatar-container">
          <div class="user-avatar-img-container">
            <img :data-not-lazy="true" v-lazy-load :data-src="`${userInfo.parentAvatar}?im=Resize=(152,152)`" alt="avatar">
          </div>
        </div>
        <div ref="userNameContainer" class="user-name-contaienr text-overflow-ellipsis">
          <div v-if="!isEditing">
            <span class="nick-name">{{ editNickname || userInfo.parentNickname || $t('common.parent') }}</span>
            <img
              class="edit-icon"
              v-lazy-load data-src="~assets/images/my-account/icon-edit.png"
              alt=""
              @click="toggleEdit"
            >
          </div>
          <input
            v-else
            ref="editInput"
            v-model="editNickname"
            class="edit-input"
            type="text"
          >
        </div>
      </div>
    </div>
    <!-- 个人中心资源位 -->
    <div v-if="isShowAdvertising" class="student-portal-advertising-space">
      <el-carousel indicator-position="none" height="106px" arrow="always" loop class="advertising-imgs">
        <el-carousel-item v-for="(item, index) in advertisingData" :key="index">
          <img v-lazy-load :data-src="item.src" alt="" :data-not-lazy="true" @click="adClickHandler(item)">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="nav-list-container">
      <div v-for="(nav, index) in navList" :key="index" class="nav-list-group">
        <div class="nav-list-group-detail font-medium">
          <div v-for="(navItem, itemIndex) in nav.list" :key="itemIndex" @click="navChangeHandler(navItem)">
            <nuxt-link class="nav-item hover-transition" :class="{'selected': currentRoutePath.includes(navItem.keyword)}" :nav-name="navItem.name" :to="navItem.url">
              <i class="iconfont" :class="navItem.iconName" /><span>{{ navItem.name }}</span>
              <img v-if="navItem.mark&& hasUnpaidList" class="mark" v-lazy-load data-src="~assets/images/my-account/unpaid_icon.png" alt="">
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { oneApi } from 'config/api';
import bus from 'common/bus.js';
import { resourceLocationMap, resourceLocationSaMap } from 'common/map.js';
import { adShow, adClick } from 'common/sensors.js';
import { Storage } from 'common/utils';

export default {
  data() {
    const studentId = this.$route.query.studentId || '';
    return {
      studentId,
      checkOldStatus: false,
      showEditAvatar: false,
      navList: this.$t('common.sideNavigationLinks'),
      showInviteEntrance: false,
      activityId: '',
      advertisingData: [],
      isEditing: false,
      editNickname: '',
    }
  },
  computed: {
    locale() {
      return this.$store.state.locale
    },
    userInfo() {
      return this.$store.state.ucenter.user;
    },
    hasUnpaidList() {
      return (this.$store.state.ucenter.unpaidList.toBeContinueSku || this.$store.state.ucenter.unpaidList.inCartSku) && this.isNotReminded
    },
    currentRoutePath: {
      set() {},
      get() {
        return this.$route.path
      },
    },
    isNotReminded() {
      const storage = new Storage();
      return !storage.getItem('figure_remind')
    },
    isShowAdvertising() {
      return this.advertisingData.length > 0
    },
  },
  mounted() {
    this.getResourceData()
    bus.$on('myAccount.switchedSuccessfully', () => {
      // this.checkOld();
    });
  },
  beforeDestroy() {
    bus.$off('myAccount.switchedSuccessfully');
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    navChangeHandler(navItem) {
      this.currentRoutePath = navItem.url;
    },
    handleClickOutside(event) {
      if (this.isEditing && !this.$refs.userNameContainer.contains(event.target)) {
        this.saveNickname();
      }
    },
    toggleEdit(event) {
      event.stopPropagation()
      this.isEditing = true;
      this.editNickname = this.userInfo.parentNickname;
      this.$nextTick(() => {
        this.$refs.editInput.focus();
      });
      document.addEventListener('click', this.handleClickOutside);
    },
    async saveNickname() {
      document.removeEventListener('click', this.handleClickOutside);
      const {
        phone, countryCallingCode, email, linkedAccount,
      } = this.userInfo
      const params = {
        nickName: this.editNickname,
        phone,
        email,
        countryCallingCode,
        linkedAccount,
      }
      const res = await this.$axios.post(oneApi.modifyParentInfo, params, { rewritePostBody: true });

      if (Number(res.code) !== 0) {
        this.$ElMessage.success({ message: res.msg, customClass: 'el-message-toast', duration: 1000 });
        return;
      }
      this.$ElMessage.success({ message: this.$t('bindForm.bindSuccess'), customClass: 'el-message-toast', duration: 1000 });
      this.isEditing = false
      // 更新用户信息
      await this.$store.dispatch('ucenter/queryUserBasicInfo', { $axios: this.$axios, userData: { fillInAttention: false } });
    },
    async toPlacementTest() {
      const data = {
        gradeId: this.$store.state.ucenter.user.gradeId || this.$store.state.courses.selectedGrade,
      }

      if (!data.gradeId) {
        await this.$store.dispatch('courses/queryGradeList', { $axios: this.$axios });
        data.gradeId = this.$store.state.courses.selectedGrade;
      }

      const res = await this.$axios.post(oneApi.queryEvaluation, data, { rewritePostBody: true })
      if (Number(res.code) !== 0) {
        return this.$ElMessage.error(res.message || res.msg)
      }
      const url = res.data.goExamUrl
      if (url.includes('?')) {
        window.open(`${res.data.goExamUrl}&utoken=${res.data.utoken}`, '_blank')
      } else {
        window.open(`${res.data.goExamUrl}?utoken=${res.data.utoken}`, '_blank')
      }
      return res
    },
    async getResourceData() {
      const data = [{
        locationKey: resourceLocationMap.get('student-protal'),
      }]

      const res = await this.$axios.post(oneApi.queryResourceByLocation, data, { rewritePostBody: true })

      if (res.code !== 0) {
        return
      }

      const resData = res.data || []

      if (resData.length === 0) return

      const resourcesList = resData[0].resources || []

      if (resourcesList.length === 0) return

      // eslint-disable-next-line prefer-destructuring
      this.advertisingData = resourcesList

      this.adShowHandler(resourcesList[0])
    },
    adClickHandler(currentAd) {
      window.open(currentAd.url, currentAd.target ? '_blank' : '_self')
      adClick(this, resourceLocationSaMap.get('student-protal'), currentAd.resId)
    },
    adShowHandler(currentAd) {
      adShow(this, resourceLocationSaMap.get('student-protal'), currentAd.resId)
    },
  },
}
</script>
<style lang="scss">
.advertising-imgs {
  .el-carousel__arrow {
    width: 24px;
    height: 24px;
    background-color: rgba(34, 34, 34, 0.4);
  }

  .el-carousel__arrow:hover {
    background-color: rgba(34, 34, 34, 0.6);
  }
}
</style>
<style lang="scss" scoped>
.side-navigation-bar-wrapper {
  min-width: 324px;
  max-width: 342px;

  // 个人中心资源位
  .student-portal-advertising-space {
    margin: 24px auto;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;

    .advertising-imgs {
      background: #fff;
      box-shadow: 0 6px 23px 9px rgba(122, 122, 122, 0.06);
      border-radius: 8px;
    }

    .arrow-direction {
      width: 24px;
      height: 24px;
    }
  }

  .nav-list-container {
    padding: 40px 0;
    min-width: 224px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 6px 23px 9px rgba(122, 122, 122, 0.06);

    .nav-list-group {
      margin-bottom: 40px;

      .nav-list-group-title {
        margin-bottom: 20px;
        padding: 0 24px;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.3px;
        color: var(--txt-color-lv1);
      }

      .nav-list-group-detail {
        cursor: pointer;
      }

      .nav-list-group-detail .nav-item {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        padding: 0 24px;
        font-size: 16px;
        line-height: 32px;
        letter-spacing: 0;
        // word-spacing: -2px;
        color: #666;
        cursor: pointer;

        .iconfont {
          display: block;
          margin-right: 12px;
          width: 32px;
          height: 32px;
          text-align: center;
          line-height: 32px;
          font-size: 18px;
          background: var(--txt-color-link3);
          border-radius: 50%;
          color: var(--txt-color-link);
        }

        &:hover {
          color: var(--txt-color-link);

          .iconfont {
            color: #fff;
            background: var(--txt-color-link);
          }
        }

        &.selected {
          color: var(--txt-color-link);

          .iconfont {
            color: #fff;
            background: var(--txt-color-link);
          }
        }
      }
    }
  }

  .user-card-container {
    position: relative;
    margin-bottom: 24px;
    padding: 30px 24px;
    background: #fff;
    box-shadow: 0 6px 23px 9px rgba(162, 170, 184, 0.06);
    border-radius: 10px;

    .operate-btn-group {
      position: absolute;
      top: 24px;
      right: 24px;
      display: flex;

      .icon-btn {
        margin-left: 8px;
        width: 32px;
        height: 32px;
        text-align: center;
        color: #fff;

        .iconfont.icon-edit,
        .iconfont.icon-user-switch {
          font-size: 28px;
        }
      }
    }

    .user-avatar-container {
      width: 80px;
      height: 80px;
      cursor: pointer;

      .user-avatar-img-container {
        width: 80px;
        height: 80px;
        border-radius: 50%;

        img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
        }
      }
    }

    .user-name-contaienr {
      position: relative;
      margin-top: 12px;
      height: 30px;
      font-size: 28px;
      font-weight: bold;
      color: #222;
      line-height: 28px;
      .nick-name {
        display: inline-block;
        max-width: 260px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .edit-input {
        font-size: 28px;
        height: 28px;
        font-family: 'Montserrat-Bold', 'Montserrat', sans-serif;
        font-weight: bold;
        color: #222222;
        caret-color: var(--txt-color-link);
        line-height: 28px;
        border: none;
        outline: none;
        border-bottom: 1px solid var(--txt-color-link);
        width: 100%;
        background: transparent;
      }

      .edit-icon {
        cursor: pointer;
        width: 20px;
        height: 20px;
        &:hover {
          color: orange;
        }
      }
    }

    .user-phone-contaienr,
    .user-num-contaienr {
      text-align: center;
      margin-bottom: 2px;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.46px;
      color: var(--txt-color-link3);
    }
  }

  i.icon-setting {
    display: inline-block;
    width: 16px;
    height: 19px;
    background: center/100% no-repeat url("~assets/images/my-account/setting.png");
  }
}
</style>
