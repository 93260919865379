export default {
  pageTitle: '下載Think Academy客户端',
  studentApp: '學生端',
  studentInfo: '沉浸式互動線上課堂，激發學習興趣',
  parentApp: '家長端',
  parentInfo: '全面瞭解孩子學習進度，實时獲得孩子學情迴響',
  deviceTitle: '設備要求',
  windowsRequirement: {
    title: 'Windows要求',
    info1: '操作系統： Windows 7及以上版本',
    info2: '最低配置：CPU 4 核，4GB RAM',
    info3: '設備：不支持使用設備Chromebook和Surface Book',
  },
  macRequirement: {
    title: 'Mac要求',
    info1: '操作系統：MacOS 10.12及以上',
    info2: '最低配置：CPU 4 核，4GB RAM',
  },
  ipadRequirement: {
    title: 'iPad要求',
    info1: '操作系統：iOS 10.0及以上版本',
    info2: '設備：iPad 5、iPad Air 3、iPad mini 5和所有更新型號',
  },
  iphoneRequirement: {
    title: 'iPhone要求',
    info1: '操作系統：iOS 10.0及以上版本',
    info2: '設備：iPhone7/Plus及所有更新型號',
  },
  androidRequirement: {
    title: 'Android要求',
    info1: '操作系統：Android 6.0及以上',
    info2: '最低配置： (Tablet)2.0 GHz處理器速度，4GB RAM',
  },
  parentThinkAcademy: 'Think&nbsp;Academy家長端',
  studentThinkAcademy: 'Think&nbsp;Academy學生端',
  download: '下載',
  studentDownloadInfo: {
    title: '沉浸式互動網上課程以激發興趣',
    step1: '步驟 1',
    step1Info: '為確保您的孩子擁有良好的課堂體驗，我們建議使用電腦、iPad 或 Android 平板下載學生應用程序：Think Academy Student。如果需要使用手機，請點擊下載按鈕。',
    step2: '步驟 2',
    step2Info: '打開 “Think Academy Student” 並進入教室。請使用您 Think Academy Student 帳號登錄學生應用程序，以確認您選擇了正確的孩子。孩子們可以在課程開始前15分鐘進入教室。',
  },
}
