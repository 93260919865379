/**
 * Google 跟踪代码管理器
 */

export default function ({ app, store }) {
  // 客户端环境不执行
  if (process.client) return;
  // 判断环境
  app.head.script.push({
    hid: "dataLayer",
    innerHTML: `window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        'page': {
          'path': window.location.pathname,
          'title': document.title
        }
      });`,
    type: "text/javascript",
  });

  if (process.env.runtime !== "prod" && process.env.runtime !== "preprod")
    return;
  if (store.state.locale === "us") {
    app.head.script.push({
      hid: "gtm",
      innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-T56B7NR');`,
      defer: true,
    });
  }

  if (store.state.locale === "sg") {
    app.head.script.push({
      hid: "gtm",
      innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-MMQQF2D');`,
      defer: true,
    });
    app.head.script.push({
      type: "application/ld+json",
      json: {
        "@context": "http://schema.org",
        "@type": "Product",
        name: "Primary School Math Olympiad Training (Novena)",
        description: "Primary School Math Olympiad Training (Novena)",
        url: "https://www.thinkacademy.sg/courses/list",
        brand: {
          "@type": "Brand",
          logo: "https://download-pa-s3.thethinkacademy.com/static-pa/project/thinkacademy-website-web/prod/images/2cea6ff.png",
        },
        offers: {
          "@type": "Offer",
          price: "S$70 /lesson",
        },
      },
    });
    app.head.script.push({
      type: "application/ld+json",
      json: {
        "@context": "http://schema.org",
        "@type": "LocalBusiness",
        name: "Think Academy Singapore",
        image:
          "https://download-pa-s3.thethinkacademy.com/static-pa/project/thinkacademy-website-web/prod/images/2cea6ff.png",
        telephone: "+65-91055348",
        email: "",
        address: {
          "@type": "PostalAddress",
          streetAddress: "10 Sinaran Dr, #03-26 29 Square 2, Singapore 307506",
          addressCountry: "Singapore",
          postalCode: "307506",
        },
        openingHoursSpecification: {
          "@type": "OpeningHoursSpecification",
          dayOfWeek: {
            "@type": "DayOfWeek",
            name: "Tuesday-Sunday",
          },
          opens:
            "Please insert valid ISO 8601 date/time here. Examples: 2015-07-27 or 2015-07-27T15:30",
          closes:
            "Please insert valid ISO 8601 date/time here. Examples: 2015-07-27 or 2015-07-27T15:30",
        },
        url: "https://www.thinkacademy.sg/",
      },
    });
  }

  if (store.state.locale === "hk") {
    app.head.script.push({
      hid: "gtm",
      innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-TQ5842K8');`,
      defer: true,
    });
  }

  if (store.state.locale === "tm") {
    app.head.script.push({
      hid: "gtm",
      innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-NCVVKMC');`,
      defer: true,
    });
    app.head.meta.push({
      name: "google-site-verification",
      content: "No76MogO-xIQWedlvvMnVLKHl2LIlGfjK1eHQXwmIP8",
    });
    app.head.meta.push({
      name: "ahrefs-site-verification",
      content:
        "d4ac6f00f5bbd6bf9271a100c1a0903fa7683b1a7fdf5e8a59dc5a52c51ef95f",
    });
    // Mediago 埋点
    app.head.script.push({
      innerHTML: `window._megoaa = window._megoaa || [];
      window._megoaa.push({ type: 'event', name: 'pageview'});
      window._megoaa.push({ type: 'nextjump', link: ["think-matrix.com"]});
      !function (t, f, a, x) {
      if (!document.getElementById(x)) {
      t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
      }
      }(document.createElement('script'),
      document.getElementsByTagName('script')[0], '//cdn.mediago.io/js/pixel.js', 'pixel_megoaa_script');`,
      defer: true,
    });
    // 谷歌新埋点
    app.head.script.push({
      src: "https://www.googletagmanager.com/gtag/js?id=AW-11168801903",
      defer: true,
    });
    app.head.script.push({
      innerHTML: `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AW-11168801903',{'allow_enhanced_conversions':true});`,
      defer: true,
    });
    app.head.script.push({
      innerHTML: `window.addEventListener('load', function (event) {
        document.querySelectorAll('[class="not-logged-in"]').forEach(function (e) { //ALL pop up element
        e.addEventListener('click', function () {
        //the form submit event code START
        document.querySelectorAll("button.el-button.button.login-button.new-login-font.el-button--primary").forEach(function(e)
        {
        e.addEventListener('click',function()
        {
        var email = document.querySelector('[placeholder="Email Address"]').value;
        if(email != "" && email.includes("@"))
        {
        gtag("set", "user_data", {
                            email: email
                        });
        gtag('event', 'conversion', {'send_to': 'AW-11168801903/D1EGCIa97-MYEO_I2c0p'});
        }
        })
        })
        //the form submit event code END
        });
        });
        });`,
      defer: true,
    });
    app.head.script.push({
      innerHTML: `window.addEventListener('load', function (event) {
        document.querySelectorAll('[class="info-text"]').forEach(function (e) { //ALL pop up element
        e.addEventListener('click', function () {
        setTimeout(function () {
        //the form submit event code START
        document.querySelectorAll('[class="normal"]').forEach(function(e)
        {
        e.addEventListener('click',function()
        {
        var area = document.querySelector("li.el-select-dropdown__item.phone-area-code-option.selected > div > div.area-code").innerText.split(" ")[1].split("\n")[0];
        var phone = document.querySelector('[placeholder="Mobile Phone"]').value;
        var full = area + phone;

        if(phone != "" && area != "")
        {
        gtag('set','user_data',{
        'phone_number': full
        });
        gtag('event', 'conversion', {'send_to': 'AW-11168801903/D1EGCIa97-MYEO_I2c0p'});
        }
        })
        })
        //the form submit event code END
        }, 1500);
        });
        });
        });`,
      defer: true,
    });
    app.head.script.push({
      innerHTML: `window.addEventListener('load', function () {
        if(window.location.href.includes("/order/result/")){
        var transaction_id = Date.now();
        var currency = "USD";
        var price = localStorage.getItem("price");
        gtag('event', 'conversion', {
        'send_to': 'AW-11168801903/tUaoCKyp6eMYEO_I2c0p',
        'transaction_id': transaction_id,
        'value': price,
        'currency': currency
        });
        }
        })`,
      defer: true,
    });
  }
}
