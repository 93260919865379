import bus from 'common/bus'

export default {
  data() {
    return {
      isShow: true,
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.ucenter.isLogin
    },
  },
  methods: {
    closeHandle() {
      this.isShow = false
    },
    linkJump() {
      this.cardTrackReport({ cardName: 'webMaket' })
      console.log('linkJump', this.linkUrl, this.target, this.skinType, this.functionTyp)
      if (this.skinType === 0) {
        if (this.target === '_blank') {
          window.open(this.linkUrl, '_blank')
        } else {
          window.location.href = this.linkUrl
        }
      } else if (this.skinType === 1) {
        if (this.functionTyp === 1) {
          if (!this.isLogin) {
            bus.$emit('login', { triggerSource: 'appointment' });
            return
          }
          bus.$emit('toShowDiagnosis')
        }
      }
    },
    touchJump() {
      this.cardTrackReport({ cardName: 'touchMaket' })
      console.log('linkJump', this.linkUrl, this.target, this.skinType, this.functionTyp)
      if (this.skinType === 0) {
        if (this.target === '_blank') {
          window.open(this.linkUrl, '_blank')
        } else {
          window.location.href = this.linkUrl
        }
      } else if (this.skinType === 1) {
        if (this.functionTyp === 1) {
          if (!this.isLogin) {
            const currentFullPath = this.$route.fullPath
            const backUrl = encodeURI(currentFullPath)
            this.$router.replace(`/login?cancelUrl=${backUrl}&successUrl=${backUrl}&triggerSource=appointment`)
            return
          }
          const currentFullPath = this.$route.fullPath
          const backUrl = encodeURI(currentFullPath)
          this.$router.replace(`/appointmentDiagnosis?successUrl=${backUrl}`);
        }
      }
    },
  },
  props: {
    // 组件名称
    componentName: {
      default: '',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    // web图片
    webUrl: {
      default: '',
      type: String,
    },
    // touch图片
    touchUrl: {
      default: '',
      type: String,
    },
    // 图片位置 1居中 2右
    position: {
      default: 1,
      type: Number,
    },
    // 图片链接
    linkUrl: {
      default: '',
      type: String,
    },
    // 跳转方式 _blank新窗口 _self当前窗口
    target: {
      default: '_self',
      type: String,
    },
    // 跳转类型 0自定义跳转 1功能性跳转 2无跳转
    skinType: {
      default: 0,
      type: Number,
    },
    // 功能性跳转类型 1 预约诊断
    functionTyp: {
      default: 1,
      type: Number,
    },
  },
}
