<template>
  <div class="normal-legal-statement-checkbox-wrapper">
    <div class="input-checkbox" :class="{'selected': value}" @click="valueChangeHandler">
      <i v-if="value" class="iconfont icon-success" />
    </div>
    <div v-if="content" class="content font-medium" @click="valueChangeHandler" v-html="content" />
    <div v-else class="content font-medium" @click="valueChangeHandler">
      <slot />
    </div>
    <div v-show="!value" :class="['shake-box', shaking]">
      {{ tipMessage }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: false,
      type: Boolean,
    },
    content: {
      default: '',
      type: String,
    },
    tipMessage: {
      default: '',
      type: String,
    },
    legalCount: {
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      shaking: '',
    }
  },
  watch: {
    legalCount() {
      console.log('hahaha')
      this.shaking = 'shaking'
      this.$nextTick(() => {
        setTimeout(() => {
          this.shaking = ''
        }, 1000)
      })
    },
  },
  mounted() {
    this.getHopeCupSiteInfo()
  },
  methods: {
    valueChangeHandler() {
      const newVal = !this.value;
      this.$emit('update:value', newVal);
      this.$emit('change', newVal);
    },
    // 获取跳转链接
    getHopeCupSiteInfo() {
      const elLink = document.getElementById('link-sg-terms-use')
      if (!elLink) return
      const isHopePath = this.currentRoutePath === '/hope-cup'
      const sessionSourceSite = window.sessionStorage.getItem('source_site') || ''
      if (isHopePath || sessionSourceSite === 'hopecup') {
        elLink.setAttribute('target', '')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.normal-legal-statement-checkbox-wrapper {
  position: relative;
  display: flex;

  .input-checkbox {
    flex-shrink: 0;
    flex-grow: 0;
    width: 18px;
    height: 18px;
    margin-right: 10px;
    background: #f5f5f5;
    border-radius: 2px;
    border: 2px solid #e6e6e6;
    cursor: pointer;

    .iconfont {
      position: relative;
      top: -1px;
      font-size: 14px;
      color: #fff;
    }

    &.selected {
      background: var(--txt-color-link);
      border-color: var(--txt-color-link);
    }
  }

  .content {
    font-size: 12px;
    line-height: 18px;
    color: var(--txt-color-lv2);
    cursor: pointer;

    /deep/ a {
      color: var(--txt-color-link);
      text-decoration: underline;

      &:hover {
        color: var(--txt-color-link2);
      }
    }
  }

  .shake-box {
    position: absolute;
    top: -35px;
    left: 0;
    height: 26px;
    padding: 0 10px;
    background: #3370ff;
    border-radius: 2px;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    line-height: 26px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 6px;
      bottom: -6px;
      width: 12px;
      height: 6px;
      background: url('~assets/web/images/common/shake-arrow@2.png') no-repeat;
      background-size: 12px;
    }

    &.shaking {
      animation: shakeBody 0.3s ease-in-out;
      animation-iteration-count: 2;
    }
  }

  @keyframes shakeBody {
    0% {
      transform: translateX(0);
    }

    50% {
      transform: translateX(10px);
    }

    75% {
      transform: translateX(0);
    }

    90% {
      transform: translateX(10px);
    }

    100% {
      transform: translateX(0);
    }
  }
}
</style>
