<template>
  <div class="get-notified-wrapper">
    <div v-if="title || intro" class="get-notified-head">
      <div v-if="title" class="title" v-text="title" />
      <div v-if="intro" class="description" v-text="intro" />
    </div>
    <div class="get-notified-body">
      <el-form
        ref="formData"
        :model="formData"
        :rules="rules"
        :hide-required-asterisk="true"
        class="invisible-lable"
      >
        <div class="form-wrapper" :class="{ has: hasFirst }">
          <template v-for="(item, index) in formData.dataList">
            <div
              v-if="item.show"
              :key="index"
              class="form-item"
              :style="`${
                item.key === 'givenName' || item.key === 'familyName'
                  ? 'width: 225px;'
                  : ''
              }`"
            >
              <el-form-item
                :prop="'dataList.' + index + '.value'"
                :rules="item.rules"
              >
                <template v-if="item.optionsType === 'input'">
                  <template
                    v-if="item.key === 'gradeId' && item.custom === false"
                  >
                    <el-select
                      v-model="item.value"
                      :placeholder="item.name"
                      :multiple="false"
                      popper-class="normal-grace-dropdown"
                      @change="handleGradeChange"
                    >
                      <el-option
                        v-for="(ele, i) in gradeList"
                        :key="i + 'a'"
                        :label="ele.name"
                        :value="ele.value"
                      />
                    </el-select>
                  </template>
                  <template
                    v-else-if="item.key === 'phone' && item.custom === false"
                  >
                    <el-input v-model="item.value" :placeholder="item.name">
                      <template slot="prepend">
                        <el-select
                          v-model="phoneAreaCode"
                          :popper-append-to-body="false"
                          class="phone-area-code-dropdown form-phone-code-select normal-grace-dropdown text-left"
                          value-key="countryCode"
                          @change="handleAreaCodeChange"
                        >
                          <el-option
                            v-for="code in phoneCodeList"
                            :key="code.countryCode"
                            class="phone-area-code-option"
                            :label="`+${code.value}`"
                            :value="code"
                          >
                            <div>
                              <span class="area-code">+{{ code.value }}</span>
                              <span class="area-name">{{
                                code.countryName
                              }}</span>
                            </div>
                          </el-option>
                        </el-select>
                      </template>
                    </el-input>
                  </template>
                  <template
                    v-else-if="item.key === 'email' && item.custom === false"
                  >
                    <el-input
                      v-model="item.value"
                      :placeholder="item.name"
                      @input="setEmailPattern"
                    />
                  </template>
                  <template v-else>
                    <el-input v-model="item.value" :placeholder="item.name" />
                  </template>
                </template>
                <template v-else-if="item.optionsType === 'select'">
                  <el-select
                    v-model="item.value"
                    :placeholder="item.name"
                    :multiple="false"
                    popper-class="normal-grace-dropdown"
                  >
                    <el-option
                      v-for="(option, optionIndex) in item.options"
                      :key="optionIndex"
                      :label="option.name"
                      :value="optionIndex"
                    />
                  </el-select>
                </template>
                <template v-else-if="item.optionsType === 'selectMore'">
                  <el-select
                    v-model="item.value"
                    :placeholder="item.name"
                    :multiple="true"
                    popper-class="normal-grace-dropdown"
                  >
                    <el-option
                      v-for="(option, optionIndex) in item.options"
                      :key="optionIndex"
                      :label="option.name"
                      :value="optionIndex"
                    />
                  </el-select>
                </template>
              </el-form-item>
            </div>
          </template>
        </div>
        <div v-if="formData.emailShow" class="checkbox-wrapper">
          <el-checkbox v-model="formData.subStatus" class="el-checkbox-round">
            {{ agreementNotice }}
          </el-checkbox>
        </div>

        <div class="button-wrapper">
          <el-button
            class="button common-button-normal"
            :loading="loading"
            @click="handleSubmit"
          >
            {{ submit || submitName }}
          </el-button>
        </div>
      </el-form>
    </div>

    <ConfirmDialog
      :show.sync="diaLogShow"
      :content="successNotice"
      :main-btn-text="$t('page.common.confirm')"
    />
  </div>
</template>
<script>
import mixin from "./mixin";
import trackReportMixin from "../Common/CommonMixin/trackReport";
import { oneApi } from "config/api";
import dataReport from "common/dataReport";
import ConfirmDialog from "@/components/web/common/ConfirmDialog/index.vue";
// import gRecaptcha from '@thinkacademy/vitas-utils/gRecaptcha';

export default {
  components: {
    ConfirmDialog,
  },
  mixins: [mixin, trackReportMixin],
  data() {
    return {
      diaLogShow: false,
    };
  },
  mounted() {
    this.init();
    this.initFormData();
    this.handleAreaCodeChange();
  },
  methods: {
    // 处理提交按钮操作
    handleSubmit() {
      this.$refs.formData.validate((valid) => {
        if (!valid) {
          return false;
        }

        // 打开Loading
        this.loading = true;
        this.save(() => {
          this.diaLogShow = true;
        });
        return true;
      });
    },

    // 数据保存
    async save(callback) {
      // const { verifyKey } = process.env;
      // const token = await gRecaptcha.getToken.call(this, verifyKey)
      // this.$store.commit('verifyToken/SEND_GRC_TOKEN', token)
      this.$store.commit("verifyToken/SEND_GRC_ACTION", "leads");
      const data = {
        sourceCode: "website",
        mediaCode: "pc_lead",
        tags: [],
        yachProfile: {},
        formData: this.getDataParamsList(),
        componentId: this.moduleId,
        componentType: this.type,
        componentName: this.title,
        emailSubscribe: false,
      };

      if (this.clue !== "") {
        data.tags.push(this.clue);
      }

      if (this.host !== "") {
        data.yachProfile.host = this.host;
      }

      if (this.secret !== "") {
        data.yachProfile.secret = this.secret;
      }

      this.formData.dataList.forEach((item) => {
        if (
          item.key === "email" &&
          item.custom === false &&
          this.formData.subStatus
        ) {
          data.emailSubscribe = true;
        }
      });

      // 保存数据接口
      const res = await this.$axios.post(oneApi.submitLeads, data, {
        rewritePostBody: true,
      });
      // 关闭Loading
      this.loading = false;

      // 业务异常阻断
      if (res.code !== 0) {
        return this.$ElMessage({
          message:
            res.code === -2 || res.code === 10 || res.code === 11
              ? this.errorNotice
              : res.msg,
          type: "warning",
        });
      }

      // 埋点上报
      this.buttonTrackReport({
        buttonName: this.submitName,
      });
      window.dataLayer.push({
        event: "generatelead_gtm",
      });
      // 留资组件2提交第三方埋点上报
      dataReport.launchReport({
        fbqOptions: {
          name: "Lead",
        },
        ttqOptions: {
          name: "SubmitForm",
        },
      });
      if (this.$store.state.locale === "tm") {
        this.mediagoTrack();
      }

      // 清空表单
      this.$refs.formData.resetFields();

      // 执行回调
      if (callback) {
        callback();
      }
      return res;
    },

    // 处理年级选择事件
    handleGradeChange(val) {
      const gradeItem = this.gradeList.filter((item) => item.value === val);
      this.formData.gradeName = gradeItem[0].name;
    },

    // 设置邮箱校验正则
    setEmailPattern() {
      this.formData.dataList.forEach((item) => {
        if (item.key === "email" && item.custom !== true) {
          // eslint-disable-next-line no-param-reassign
          item.rules = [
            {
              required: !!item.required,
              message: this.requiredError,
              trigger: "blur",
            },
            {
              pattern:
                /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,8})$/,
              message: this.emailError,
              trigger: "blur",
              formatter: (value) => value.trim(),
            },
          ];
        }
      });
    },

    // 手机区号变更事件
    handleAreaCodeChange() {
      this.formData.dataList.forEach((item) => {
        if (item.key === "phone" && item.custom !== true) {
          this.setPhonePattern(item);
          if (item.value === "") return;
          this.$refs.formData.validateField("Phone");
        }
      });
    },

    /**
     * 设置手机号校验正则
     */
    setPhonePattern(item) {
      const { reg } = this.phoneAreaCode;
      // eslint-disable-next-line no-param-reassign
      item.rules = [
        {
          required: !!item.required,
          message: this.requiredError,
          trigger: "blur",
        },
        {
          pattern: reg,
          message: this.phoneError,
          trigger: "blur",
        },
      ];
    },
    // 百度广告投放埋点
    mediagoTrack() {
      // eslint-disable-next-line no-underscore-dangle
      window._megoaa = window._megoaa || [];
      // eslint-disable-next-line no-underscore-dangle
      window._megoaa.push({
        type: "event",
        name: "Lead",
        acid: "25112",
        pxd: "781876808337301",
      });
    },
  },
};
</script>
<style>
.long-toast-pc {
  margin-top: 44vh !important;
}
</style>
<style lang="scss" scoped>
/* stylelint-disable */
.get-notified-wrapper {
  width: 1240px;
  margin: 0 auto;
  padding: 40px 0;
}

.get-notified-head {
  width: 1000px;
  margin: 0 auto;

  .title {
    line-height: 30px;
    color: var(--txt-color-lv1);
    font-size: 24px;
    text-align: center;
    word-wrap: break-word;
  }

  .description {
    margin-top: 20px;
    font-size: 14px;
    color: var(--txt-color-lv1);
    line-height: 20px;
    text-align: center;
    word-wrap: break-word;
  }
}

.get-notified-body {
  width: 960px;
  margin: 0 auto;
  margin-top: 40px;

  .form-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  /deep/.el-form-item {
    border: none;
    background: none;

    .el-input__suffix {
      right: 25px;
    }

    .el-form-item__error {
      left: 30px;
    }

    .el-select {
      display: inline-block;
      width: 100%;

      .el-select__tags {
        height: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .el-icon-arrow-up {
        color: #171717;
        font-weight: bold;
      }
    }

    .el-form-item__content {
      border: 1px solid rgba(23, 43, 77, 0.1);
      border-radius: 4px;
      line-height: unset;
    }

    .el-input__inner {
      height: 60px;
      border: none;
      background: none;
      font-weight: 500;
      font-size: 16px;
      color: var(--txt-color-lv1);
      padding: 0 20px;
      font-family: "Montserrat", "SF Pro Rounded", "SF Pro", "Helvetica",
        "Roboto", "Arial", sans-serif;

      &::placeholder {
        color: var(--txt-color-lv2);
      }
    }

    .el-input-group__prepend {
      border: none;
      background: none;
    }
  }

  .form-item-phone {
    /deep/.el-form-item {
      .el-input__inner:last-child {
        padding-left: 0;
      }
    }
  }

  /deep/.checkbox-wrapper {
    display: flex;
    justify-content: center;

    .el-checkbox__label {
      color: var(--txt-color-lv1);
      font-size: 16px;
    }
  }

  .button-wrapper {
    margin-top: 20px;
    text-align: center;

    .common-button-normal {
      height: 48px;
      display: inline-flex;
      line-height: 48px;
      width: 160px;
    }
  }

  .form-item {
    width: 470px;

    &:nth-child(2n + 1):last-child {
      width: 960px;
      margin-right: 0;

      /deep/.phone-area-code-dropdown {
        .phone-area-code-option {
          width: 960px;
        }
      }
    }
  }

  .has .form-item {
    width: 470px;

    &:nth-child(2n):last-child {
      width: 960px;
      margin-right: 0;

      /deep/.phone-area-code-dropdown {
        .phone-area-code-option {
          width: 960px;
        }
      }
    }
    &:nth-child(2n + 1):last-child {
      width: 470px;
      margin-right: 0;

      /deep/.phone-area-code-dropdown {
        .phone-area-code-option {
          width: 470px;
        }
      }
    }
  }

  /deep/.phone-area-code-dropdown {
    .el-scrollbar {
      height: inherit;
    }

    .el-select-dropdown {
      margin: 1px 0 0 -5px;
    }

    .phone-area-code-option {
      width: 470px;

      .area-code {
        display: inline-block;
        margin-right: 10px;
        width: 60px;
      }
    }
  }

  .form-phone-code-select {
    width: 130px !important;
    color: var(--txt-color-lv1);
  }
}
</style>
