export default {
  personalInfo: {
    registrationInfo: 'Registration information',
    contactInfo: 'Contact information',
    externalBind: 'External Bind',
    notBind: 'Unbound account',
    bound: 'Bound',
    leaveAcademyTip: 'You are about to leave Think Academy and jump to another page',
    unbindAccount: 'Unlink {name} account',
    afterUnbindTip: 'After unbinding, you will no longer be able to log in with your {name} account',
    bindSuccess: 'Account binding succeeds',
    loadingText: 'Loading, please wait',
    tieToAccount: 'Tie to {name}',
    accountBind: 'Account binding',
    moreSetting: 'more setting',
    registionToLogin: 'The registration information is used for your login',
  },
}
