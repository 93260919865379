export default {
  loginInfo: {
    findRelatedAcount: '找到{num}个可能是您的账号',
    existAccount: '已存在的账号',
    updateExistAccount: '您是否要更新现有账户？',
    updateRegisInfoExistAccount: '将此注册信息更新到现有账户',
    MobileOrEmail: '手机号或邮箱',
    yes: '是',
    no: '否',
    success: '账号更新成功',
    mobile: '手机',
    email: '邮箱',
    next: '下一步',
    verifyTitle: '我们向您发送了一个验证码',
    verifyContent: '请在下方输入以进行验证',
  },
}
