export default {
  loginInfo: {
    findRelatedAcount: 'Found {num} possible accounts that might be yours',
    existAccount: 'Exsiting account',
    updateExistAccount: 'Would you like to update the existing account？',
    updateRegisInfoExistAccount: 'Update this registration information to the existing account',
    MobileOrEmail: 'Mobile number or Email',
    yes: 'Yes',
    no: 'No',
    success: 'successful',
    mobile: 'Mobile',
    email: 'Email',
    next: 'Next',
    verifyTitle: 'We sent you a code',
    verifyContent: 'Please enter the verification code',
  },
}
