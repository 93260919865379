/**
 * 企微需求，根据企微转出的链接，处理诊断页面浏览信息记录
 */
import { oneApi } from 'config/api'

export default async function ({ req, $axios, route }) {
  if (process.client) return
  try {
    // 拦截路由为 /evaluation 的预约诊断页面，判断是否进行浏览信息记录
    if(route.path === '/evaluation') {
      const { url } = req.ctx
      let externalUserId = ''
      let sourceId = ''
      if (url) {
        const regex = /[?&]external_user_id=([^&#]*)/;
        // 执行正则表达式以找到匹配项
        const match = url.match(regex);
        externalUserId = match ? match[1] : '';
        
        const regex1 = /[?&]source_id=([^&#]*)/;
        // 执行正则表达式以找到匹配项
        const match1 = url.match(regex1);
        sourceId = match1 ? match1[1] : '';
      }
      if (externalUserId) {
        const params = {
          externalUserId,
          productType: 1
        }
        if (sourceId) {
          params.sourceId = sourceId
        }
        $axios.post(oneApi.recordQwLink, params, { rewritePostBody: true }).then((res) => {
          if (Number(res.code) === 0) {
            console.log('记录浏览记录成功', res);
          }
        })
      }
    }
  } catch (error) {
    console.log(error)
  }
}
