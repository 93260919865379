<template>
  <el-dialog
    v-if="show"
    :visible.sync="show"
    :show-close="false"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    width="440px"
    :custom-class="'normal-dialog login-dialog login-dialog-v1'"
    center
  >
    <template slot="title">
      <div class="dialog-header update-login-dialog-header">
        <div v-if="isSend && step === 1" class="back-icon" @click="backHandler">
          <i class="iconfont icon-more-left" />
        </div>
        <div
          v-else-if="step > 1 && step !== 4"
          class="back-icon"
          @click="backIndex(1)"
        >
          <i class="iconfont icon-more-left" />
        </div>
        <!-- <div v-else-if="isStarsChange === 3" class="back-icon" @click="backIndex(3)">
          <i class="iconfont icon-more-left" />
        </div> -->
        <div class="logo" :class="`logo-${$store.state.locale}`">
          <img v-lazy-load :data-src="logoUrl">
        </div>
        <div class="operate-dialog-icon close-icon" @click="closeHandler">
          <div class="close-icon">
            <img v-lazy-load data-src="~assets/web/images/login/close-icon@2.png">
          </div>
          <div class="close-icon hover-close-icon">
            <img v-lazy-load data-src="~assets/web/images/login/hover-close-icon@2.png">
          </div>
        </div>
      </div>
    </template>
    <div class="update-login-dialog-body">
      <!-- <h3 v-if="!isSend" class="login-headline font-semibold">
        <template v-if="firstLogin">
          {{ $t('login.login.firstLogin') }}4
        </template>
        <template v-else>
          {{ $t('login.login.loginArgain') }}1
        </template>
      </h3> -->
      <!-- -->
      <LoginForm
        ref="loginForm"
        :success-url="successUrl"
        :trigger-src="triggerSrc"
        :login-trigger-source="loginTriggerSource"
        :is-group="isGroup"
        @close-dialog="closeHandler"
        @changeTitle="textShowHandler"
        @changeCodeSend="changeCodeSend"
        @starsChange="starsChange"
        @stepChange="stepChange"
      />
    </div>
  </el-dialog>
</template>

<script>
import LoginForm from 'components/login/newLoginForm/index.vue';
// import gRecaptcha from '@thinkacademy/vitas-utils/gRecaptcha';

export default {
  components: { LoginForm },
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    successUrl: {
      default: '',
      type: String,
    },
    triggerSrc: {
      default: '',
      type: String,
    },
    loginTriggerSource: {
      default: '',
      type: String,
    },
    isGroup: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      type: 'phone',
      isSend: false,
      isAccountSend: false,
      valueCode: '',
      firstLogin: true,
      isStarsChange: 0,
      step: 1,
    };
  },
  computed: {
    logoUrl() {
      return (
        this.$store.state.website.config.logo || this.$t('common.logo.normal')
      );
    },
  },
  watch: {
    show(value) {
      if (value === true) {
        let typeText = ''
        console.log(this.isStarsChange, '107')
        if (this.isStarsChange === 1 || this.isStarsChange === 2) {
          typeText = '注册'
        }
        if (this.isStarsChange === 3) {
          typeText = '登录'
        }
        // 注册登录弹框曝光埋点
        this.Sensors.track('login_show', {
          type: typeText,
        })
      }
    },
  },
  mounted() {
    // const { verifyKey } = process.env;
    // gRecaptcha.createScript.call(this, verifyKey)
    const lastModified = localStorage.getItem('lastModified');
    if (lastModified) {
      this.firstLogin = false;
    }
  },
  methods: {
    textShowHandler(obj) {
      this.type = obj.type;
      this.isSend = obj.isSend;
      if (this.isSend) {
        this.valueCode = obj.valueCode;
      }
    },
    changeCodeSend(isAccountSend) {
      this.isAccountSend = isAccountSend;
    },
    starsChange(num) {
      this.isStarsChange = num;
    },
    stepChange(num) {
      console.log(num, 'stepChange');
      this.step = num;
    },
    backIndex(num) {
      if (this.isSend && this.step === 1) {
        this.$refs.loginForm.backFunc();
        return
      }
      if (this.isAccountSend && this.step === 2) {
        this.$refs.loginForm.backAccountSend();
        return
      }
      this.$refs.loginForm.backIndex(num);
    },
    closeHandler() {
      this.type = 'phone';
      this.isSend = false;
      this.isAccountSend = false;
      this.valueCode = '';
      // 通知父级更新弹出层显示状态
      this.$emit('update:show', false);
    },
    backHandler() {
      this.$refs.loginForm.backFunc();
    },
  },
};
</script>
<style lang="scss">
.login-dialog {
  height: 540px;
  &.login-dialog-v1 {
    height: 560px;
  }
  div {
    box-sizing: border-box;
  }

  .el-dialog__body {
    padding: 0 24px;
  }

  .update-login-dialog-body {
    padding-bottom: 16px;

    .login-headline {
      // height: 24px;
      font-size: 20px;
      font-weight: bold;
      color: var(--txt-color-lv1);
      line-height: 24px;
    }
  }

  .update-login-dialog-header {
    height: 72px;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      height: 24px;

      img {
        height: 100%;
      }
    }

    .operate-dialog-icon {
      position: relative;
      width: 24px;
      height: 24px;
      cursor: pointer;

      .close-icon {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;

        &.hover-close-icon {
          z-index: 17;
          display: none;
        }

        img {
          height: 100%;
        }
      }

      &:hover .close-icon.hover-close-icon {
        display: block;
      }
    }

    .back-icon {
      width: 24px;
      height: 24px;
      border-radius: 100%;
      text-align: center;
      line-height: 24px;
      font-size: 8px;
      color: var(--txt-color-lv2);
      cursor: pointer;

      &:hover {
        background: #f5f5f5;
        color: var(--txt-color-lv1);
      }
    }
  }
}
</style>
<style>
.grecaptcha-badge {
  visibility: hidden;
}
</style>
