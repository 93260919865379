import { oneApi } from "config/api";
import dataReport from "common/dataReport";

export default {
  props: {
    // 模块ID
    moduleId: {
      default: 0,
      type: Number,
    },
    // 组件名称
    componentName: {
      default: "",
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: "",
      type: String,
    },
    // 分校识别码
    locale: {
      default: "",
      type: String,
    },
    // 标题
    title: {
      default: "",
      type: String,
    },
    // 简介
    intro: {
      default: "",
      type: String,
    },
    // 字段列表
    fieldList: {
      default: () => [],
      type: Array,
    },
    // 消息发送密钥
    send: {
      default: "",
      type: String,
    },
  },
  data() {
    let submitName = "Submit";
    let requiredError = "The input is required.";
    let successNotice = "Thank you for your interest in Think Academy!";
    if (this.locale === "hk") {
      submitName = "提交";
      requiredError = "請輸入此必填項";
      successNotice = "提交成功，感謝您對學而思的信任!";
    }
    return {
      loading: false,
      // 表单数据
      formData: {
        dataList: [],
      },
      submitName,
      requiredError,
      successNotice,
    };
  },
  mounted() {
    this.initFormData();
  },
  methods: {
    initFormData() {
      const webClient = process.env.clientType === "web";
      const dataList = [];
      this.fieldList.forEach((item) => {
        const fieldData = {
          ...item,
          value: "",
          valueName: "", // touch端输入框回显已选择下拉列表数据
          checked: [], // touch端多选
          rules: [
            {
              required: !!item.required,
              message: this.requiredError,
              trigger: webClient ? "blur" : "onBlur",
            },
          ],
        };
        if (item.type === "select") {
          fieldData.showPopup = false;
        }
        dataList.push(fieldData);
      });
      this.formData.dataList = dataList;
    },

    async save({ successCallback, failCallback }, sourceData = {}) {
      const data = {
        origin: "",
        leads: this.getDataParamsList(),
        send: this.send,
        ...sourceData,
      };
      this.loading = true;
      const res = await this.$axios.post(oneApi.addCustomSaleslead, data, {
        rewritePostBody: true,
      });
      this.loading = false;

      if (!res || res.code !== 0) {
        if (failCallback) {
          failCallback(res.msg);
        }
        return;
      }

      this.trackReport();
      this.trackEvent();

      if (successCallback) {
        successCallback();
      }
    },

    getDataParamsList() {
      let dataParamsList = [];
      try {
        this.formData.dataList.forEach((item) => {
          let value = "";
          if (item.type === "input") {
            value = item.value;
          }
          if (item.type === "select") {
            if (item.multiple) {
              const tempList = [];
              item.value.forEach((option) => {
                tempList.push(item.options[option].name);
              });
              value = tempList.join(",");
            } else {
              value = item.options[item.value].name;
            }
          }
          dataParamsList.push({
            name: item.name,
            value,
          });
        });
      } catch (error) {
        console.error(error);
        dataParamsList = [];
      }
      return dataParamsList;
    },

    trackReport() {
      this.Sensors.track("component_button_click", {
        cms_component_type: this.componentType,
        cms_component_name: this.componentName,
        cms_component_title: this.componentTitle,
        button_name: this.submitName,
        previous_source: this.$route.name,
      });
    },
    // 留资-自定义组件提交第三方埋点上报
    trackEvent() {
      window.dataLayer.push({
        event: "generatelead_gtm",
      });
      dataReport.launchReport({
        fbqOptions: {
          name: "Lead",
        },
        ttqOptions: {
          name: "SubmitForm",
        },
      });
    },
  },
};
