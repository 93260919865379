<template>
  <div>
    <div v-show="step === 1">
      <div v-if="isSend" class="verify-headline">
        <h3 class="login-headline font-semibold">
          {{ $t('login.loginInfo.verifyTitle') }}
        </h3>
        <div v-if="locale !== 'jp'" class="login-subtitle">
          {{ $t('login.loginInfo.verifyContent') }}
        </div>
        <div class="login-tip" :class="{ 'login-exchange': locale === 'jp' }">
          <span v-if="type === 'phone'">+{{ formData.phoneAreaCode.value }}</span>
          <span>{{ formData.phoneOrEmail }}</span>
        </div>
        <div v-if="locale === 'jp'" class="login-exchange-end">
          {{ $t('login.loginInfo.verifyContent') }}
        </div>
      </div>
      <!-- 手机号表单 -->
      <el-form
        ref="loginForm"
        class="login-new-form font-medium"
        :model="formData"
        :rules="currentRules"
      >
        <el-form-item v-show="!isSend" prop="phoneOrEmail">
          <PhoneOrEmailInput
            v-model="formData.phoneOrEmail"
            :last-modified="lastModified"
            @areaChange="areaChange"
            @input-focus="reset('phoneOrEmail')"
            @update-type="updateType"
          />
        </el-form-item>
        <el-form-item v-show="isSend" prop="captcha">
          <CodeInput
            ref="phoneOrEmailCode"
            v-model="formData.captcha"
            :scene="scene"
            :type="type"
            :is-top="isTop"
            :phone-or-email="formData.phoneOrEmail"
            :area-code="areaCode"
            :cookie-name="cookieName"
            :before-send="codeBeforeSend"
            :send-success="codeSendSuccess"
            :send-error="codeSendError"
            @input-focus="reset('captcha')"
          />
        </el-form-item>
      </el-form>
      <!-- 可以切换 -->
      <div v-if="hasRepeat" class="has-repeat">
        {{ $t('login.login.ThisPhoneAlready') }}
        <span @click="toSignUp(3)">{{ $t('login.login.SignInNow') }}</span>
      </div>
      <div v-if="hasRepeat2" class="has-repeat">
        {{ $t('login.login.ItSeemsHaven') }}
        <span @click="toSignUp(2)">{{ $t('login.login.SignUpNow') }}</span>
      </div>
      <div v-if="isUnSignUp && isTop === 2" class="has-repeat">
        {{ $t('login.login.ThisPhoneAlready') }}
        <span @click="toSignLogin">{{ $t('login.login.SignInNow') }}</span>
      </div>
      <!-- 注册并登录 -->
      <div v-if="isUnSignUp && isTop === 3" class="has-repeat">
        {{ $t('login.login.ItSeemsHaven') }}
        <span @click="toSignLogin">{{ $t('login.login.SignUpNow') }}</span>
      </div>
      <div
        style="padding-top: 6px"
      >
        <div v-show="!isSend" class="update-button-container">
          <el-button
            type="primary"
            class="button login-button new-login-font"
            :loading="sendLoading"
            :disabled="!isButtonContinue"
            @click="goSendCode('phoneOrEmailCode')"
          >
            <span class="normal">
              {{ $t("login.login.loginForm.continueBtn") }}
            </span>
          </el-button>
          <div v-if="isTop === 3" style="margin-top: 16px" />
          <!-- <div v-else class="update-divider">
            <div class="update-dividet-text">
              {{ $t("login.login.loginForm.changeTipText") }}
            </div>
          </div> -->
        </div>
        <div v-show="isSend" class="update-button-container">
          <el-button
            type="primary"
            class="button login-button new-login-font"
            :loading="isLoging"
            :disabled="!isLoginSubmitAvalible"
            @click="loginSubmit"
          >
            <span class="normal">
              {{ $t("login.login.loginForm.submitBtnText") }}
            </span>
          </el-button>
        </div>
      </div>
    </div>
    <template v-if="step === 2">
      <div>
        <div v-if="!isQueryAccountCodeSend" class="verify-headline">
          <h3 class="login-headline font-semibold">
            {{ this.$t('login.loginInfo.findRelatedAcount', {num: relateList.length}) }}
          </h3>
        </div>
        <div v-else class="verify-headline">
          <h3 class="login-headline font-semibold">
            {{ $t('login.loginInfo.verifyTitle') }}
          </h3>
          <div v-if="locale !== 'jp'" class="login-subtitle">
            {{ $t('login.loginInfo.verifyContent') }}
          </div>
          <div class="login-tip" :class="{ 'login-exchange': locale === 'jp' }">
            <span v-if="selectedAccountType === 'phone'">+{{ selectedAccountInfo.countryCallingCode }}</span>
            <span>{{ selectedAccountType === 'email' ? selectedAccountInfo?.desensitizedEmail : selectedAccountInfo?.desensitizedPhone }}</span>
          </div>
          <div v-if="locale === 'jp'" class="login-exchange-end">
            {{ $t('login.loginInfo.verifyContent') }}
          </div>
        </div>
        <div v-if="!isQueryAccountCodeSend">
          <div class="exist-account-body">
            <div
              v-for="(item, index) in relateList"
              :key="index"
              :class="['exist-account-item', { 'is-active': selectedIndex === index }]"
              @click="selectItem(index)"
            >
              <div class="item-body">
                <span class="text">{{ loginConfig.existAccount }}</span>
                <span class="account">{{ item.desensitizedPhone || item.desensitizedEmail }}</span>
              </div>
              <img v-if="selectedIndex === index" class="icon" v-lazy-load data-src="~assets/web/images/login/icon-selected.png" alt="">
            </div>
          </div>
          <div class="update-button-container">
            <el-button
              type="primary"
              class="button login-button new-login-font"
              :loading="accountSendLoading"
              @click="existAccountGoSendCode"
            >
              <span class="normal">
                {{ $t("login.login.loginForm.submitBtnText") }}
              </span>
            </el-button>
          </div>
          <div class="create-operation" @click="createLogin">
            {{ $t('login.login.CreateANewAccount') }}
          </div>
        </div>
        <!-- 查询手机号发送验证码 -->
        <el-form
          v-show="isQueryAccountCodeSend"
          ref="queryAccountForm"
          class="login-new-form font-medium"
          :model="queryAccountData"
          :rules="accountRules"
        >
          <el-form-item prop="captcha">
            <CodeInput
              ref="accountCode"
              v-model="queryAccountData.captcha"
              :scene="scene"
              :type="selectedAccountType"
              :phone-or-email="!selectedAccountInfo?.phone ? selectedAccountInfo?.email : selectedAccountInfo?.phone"
              :area-code="!selectedAccountInfo?.phone ? '' : selectedAccountInfo?.countryCallingCode"
              :cookie-name="cookieName"
              :send-success="queryAccountCodeSendSuccess"
              :send-error="queryAccountCodeSendError"
              @input-focus="accountReset('captcha')"
            />
          </el-form-item>
        </el-form>
        <div v-if="isQueryAccountCodeSend" class="update-button-container">
          <el-button
            type="primary"
            class="button login-button new-login-font"
            :loading="isLoging"
            :disabled="!isAccountLoginSubmitAvalible"
            @click="existAccountLogin"
          >
            <span class="normal">
              {{ $t("login.login.loginForm.submitBtnText") }}
            </span>
          </el-button>
        </div>
      </div>
    </template>
    <template v-if="step === 3">
      <div class="bind-account-body">
        <div class="avatar">
          <img class="img" v-lazy-load :data-src="selectedAccountInfo.avatar" alt="">
        </div>
        <div class="title">
          {{ loginConfig.updateExistAccount }}
        </div>
        <div class="desc">
          {{ loginConfig.updateRegisInfoExistAccount }}
        </div>
        <div class="operation">
          <div class="bind-btn left" @click="originAccountLogin">
            {{ loginConfig.no }}
          </div>
          <div class="bind-btn right" @click="undateWithCode">
            {{ loginConfig.yes }}
          </div>
        </div>
      </div>
    </template>
    <template v-if="step === 4">
      <div class="update-container">
        <div class="success-body">
          <div class="header">
            <i class="iconfont icon-selected icon" />
            <div class="text">
              {{ loginConfig.success }}
            </div>
          </div>
          <div class="info">
            <div class="phone">
              {{ loginConfig.mobile }}: {{ type == 'phone' ? formData.phoneOrEmail : selectedAccountInfo.phone }}
            </div>
            <div class="email">
              {{ loginConfig.email }}: {{ type == 'email' ? formData.phoneOrEmail : selectedAccountInfo.email }}
            </div>
          </div>
        </div>
        <div class="update-button-container">
          <el-button
            type="primary"
            class="button login-button new-login-font"
            :loading="isLoging"
            @click="originAccountLogin"
          >
            <span class="normal">
              {{ $t("login.login.loginForm.submitBtnText") }}
            </span>
          </el-button>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import PhoneOrEmailInput from 'components/login/newLoginForm/PhoneOrEmailInput.vue';
import CodeInput from 'components/login/newLoginForm/newPhoneOrEmailCodeInput.vue';

export default {
  components: {
    PhoneOrEmailInput,
    CodeInput,
  },
  props: {
    isLoging: {
      // 登陆按钮加载中
      type: Boolean,
      default: false,
    },
    // canSwitchType: {
    //   type: Boolean,
    //   default: false,
    // },
    cookieName: {
      type: String,
      default: 'login-phone-count',
    },
    scene: {
      type: Number,
      default: 1,
    },
    step: {
      type: Number,
      default: 1,
    },
    lastModified: {
      type: Object,
      default: () => ({}),
    },
    isTop: {
      type: Number,
      default: 2,
    },
    policyAgreeStatus: {
      type: Boolean,
      default: true,
    },
    isUnSignUp: {
      type: Boolean,
      default: false,
    },
    // 关联账号列表
    relateList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const loginConfig = this.$t('login.loginInfo')
    return {
      isChecking: false, // 检测中
      loginConfig,
      contact:
        this.$t('common.contactPhone') !== ''
          ? this.$t('common.contactPhone')
          : this.$t('common.contactEmail'),
      isSend: false, // 是否切换到code页面
      isQueryAccountCodeSend: false, // 是否查询账户发送验证码
      sendLoading: false, // 按钮加载中状态
      accountSendLoading: false, // 查询账户发送验证码加载中
      formData: {
        phoneOrEmail: '',
        captcha: '',
        phoneAreaCode: {
          value: '',
        },
      },
      type: 'email',
      phoneLoginRule: {
        phoneOrEmail: [
          {
            required: true,
            message: this.$t('login.login.loginForm.phone.rules.required'),
            trigger: 'blur',
          },
        ],
        captcha: [
          {
            required: true,
            message: this.$t('login.login.loginForm.captcha.rules.required'),
            trigger: 'blur',
          },
          {
            pattern: /^\d{6}$/,
            message: this.$t('login.login.loginForm.captcha.rules.format'),
            trigger: 'blur',
          },
        ],
      },
      emailLoginRule: {
        phoneOrEmail: [
          {
            required: true,
            message: this.$t('login.login.loginForm.email.rules.required'),
            trigger: 'blur',
          },
          {
            pattern: /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+.([A-Za-z]{2,8})$/,
            message: this.$t('login.login.loginForm.email.rules.format'),
            trigger: 'blur',
          },
        ],
        captcha: [
          {
            required: true,
            message: this.$t('login.login.loginForm.captcha.rules.required'),
            trigger: 'blur',
          },
          {
            pattern: /^\d{6}$/,
            message: this.$t('login.login.loginForm.captcha.rules.format'),
            trigger: 'blur',
          },
        ],
      },
      accountRules: {
        captcha: [
          {
            required: true,
            message: this.$t('login.login.loginForm.captcha.rules.required'),
            trigger: 'blur',
          },
          {
            pattern: /^\d{6}$/,
            message: this.$t('login.login.loginForm.captcha.rules.format'),
            trigger: 'blur',
          },
        ],
      },
      phoneCodeList: this.$store.state.phoneCodeList,
      hasRepeat: false,
      hasRepeat2: false,
      selectedIndex: 0,
      legalCount: 1,
      queryAccountData: {
        captcha: '',
        phoneAreaCode: {
          value: '',
        },
      },
    };
  },
  computed: {
    isLoginSubmitAvalible() {
      return /^\d{6}$/.test(this.formData.captcha);
    },
    isAccountLoginSubmitAvalible() {
      return /^\d{6}$/.test(this.queryAccountData.captcha);
    },
    isPhoneContinue() {
      if (this.formData.phoneAreaCode.reg) {
        return this.formData.phoneAreaCode.reg.test(this.formData.phoneOrEmail);
      }
      return this.formData.phoneOrEmail !== '';
    },
    isEmailContinue() {
      const isContinue = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+.([A-Za-z]{2,8})$/.test(
        this.formData.phoneOrEmail,
      );
      return isContinue;
    },
    isButtonContinue() {
      const isContinue = this.type === 'email' ? this.isEmailContinue : this.isPhoneContinue;
      return isContinue;
    },
    isOrgSchool() {
      return this.locale === 'am' || this.locale === 'ae';
    },
    areaCode() {
      return this.formData.phoneAreaCode.value;
    },
    locale() {
      return this.$store.state.locale;
    },
    currentRules() {
      return this.type === 'email' ? this.emailLoginRule : this.phoneLoginRule;
    },
    // 选中的账号信息
    selectedAccountInfo() {
      return this.relateList[this.selectedIndex];
    },
    selectedAccountType() {
      if (this.selectedAccountInfo?.phone) {
        return 'phone';
      }
      return 'email';
    },
  },
  watch: {
    lastModified() {
      if (
        Object.keys(this.lastModified).length
        && this.lastModified.type === 'phone'
      ) {
        // eslint-disable-next-line prefer-destructuring
        const a = this.phoneCodeList.filter(
          (item) => item.value === this.lastModified.countryCallingCode,
        )[0];
        this.$nextTick(() => {
          this.formData.phoneAreaCode = a;
        });
      } else if (
        Object.keys(this.lastModified).length
        && this.lastModified.type === 'email'
      ) {
        if (this.lastModified.accountName) {
          this.formData.phoneOrEmail = this.lastModified.accountName;
        }
      }
    },
    step() {
      this.accountSendLoading = false;
    },
  },
  methods: {
    init() {
      if (
        Object.keys(this.lastModified).length
        && this.lastModified.type === 'phone'
      ) {
        const a = this.phoneCodeList.filter(
          (item) => item.value === this.lastModified.countryCallingCode,
        )[0];
        this.$nextTick(() => {
          this.formData.phoneAreaCode = a;
        });
      } else if (
        Object.keys(this.lastModified).length
        && this.lastModified.type === 'email'
      ) {
        if (this.lastModified.accountName) {
          this.formData.phoneOrEmail = this.lastModified.accountName;
        }
      }
      this.$refs.phoneOrEmailCode.init();
      this.formData.captcha = '';
      this.isSend = false;
    },
    /**
     * 触发登录按钮
     */
    loginSubmit() {
      this.$emit('changeType', this.type);
      console.log('this.formData', this.formData);
      this.$refs.loginForm.validate((isValid) => {
        if (isValid) {
          this.$emit('submit', this.formData);
        }
      });
    },
    // 触发发送验证码
    goSendCode(codInputName) {
      console.log('goSendCode', this.phoneOrEmail);
      if (!this.policyAgreeStatus && !this.isOrgSchool) {
        this.legalCount += 1;
        this.$emit('changeLegalCount');
        return;
      }
      // 埋点
      this.$emit('clickContinue');
      if (this.sendLoading || !this.isButtonContinue) {
        return;
      }
      this.sendLoading = true;
      // 加一个已注册的验证
      if (this.isTop === 2) {
        this.hasRepeat = false;

        this.$refs.loginForm.validateField('phoneOrEmail', async (errMsg) => {
          if (errMsg !== '') {
            return;
          }
          // const data = {
          //   type: this.type === 'email' ? 1 : 0,
          //   countryCallingCode: this.formData.phoneAreaCode.value,
          //   contactInfo: this.formData.phoneOrEmail,
          // };
          // console.log('校验手机号30');
          // const res = await this.$axios.post(oneApi.contactVerify, data, {
          //   rewritePostBody: true,
          // });
          // this.sendLoading = false
          // if (Number(res.code) == 100101) {
          //   this.hasRepeat = true;
          //   return;
          // } if (Number(res.code) !== 0) {
          //   this.$ElMessage({
          //     type: 'error',
          //     message: res.msg,
          //   });
          //   return;
          // }
          return this.$refs[codInputName].sendCodeHanlder();
        });
      } else if (this.isTop === 3) {
        this.hasRepeat2 = false;

        this.$refs.loginForm.validateField('phoneOrEmail', async (errMsg) => {
          if (errMsg !== '') {
            return;
          }
          // const data = {
          //   type: this.type === 'email' ? 1 : 0,
          //   countryCallingCode: this.formData.phoneAreaCode.value,
          //   contactInfo: this.formData.phoneOrEmail,
          // };
          // const res = await this.$axios.post(oneApi.contactVerify, data, {
          //   rewritePostBody: true,
          // });
          // this.sendLoading = false
          // if (Number(res.code) === 0) {
          //   this.hasRepeat2 = true;
          //   return;
          // } if (Number(res.code) !== 100101) {
          //   this.$ElMessage({
          //     type: 'error',
          //     message: res.msg,
          //   });
          //   return;
          // }
          return this.$refs[codInputName].sendCodeHanlder();
        });
      }
    },
    // 手机区号change
    areaChange(obj) {
      if (!obj) return;
      if (typeof obj === 'object') {
        this.formData.phoneAreaCode = obj;
      } else {
        const a = this.phoneCodeList.filter(
          (item) => item.value === this.lastModified.countryCallingCode,
        )[0];
        this.$nextTick(() => {
          this.formData.phoneAreaCode = a;
        });
      }

      this.setPhonePattern();
      if (this.formData.phoneOrEmail === '') return;
      this.$refs.loginForm.validateField('phoneOrEmail');
    },
    /**
     * 设置手机号校验正则
     */
    setPhonePattern() {
      const { reg } = this.formData.phoneAreaCode;
      this.phoneLoginRule.phoneOrEmail[1] = {
        pattern: reg,
        message: this.$t('login.login.loginForm.phone.rules.format'),
        trigger: 'blur',
      };
    },
    // 验证码发送前
    codeBeforeSend(callback) {
      this.$refs.loginForm.validateField('phoneOrEmail', async (errMsg) => {
        if (errMsg !== '') {
          return;
        }
        // this.sendLoading = true;
        // 绑定联系方式时需要验证联系方式是否重复
        // if (this.scene === 3 && !this.isChecking) {
        //   const data = {
        //     type: this.type === 'email' ? 1 : 0,
        //     countryCallingCode: this.formData.phoneAreaCode.value,
        //     contactInfo: this.formData.phoneOrEmail,
        //   };
        //   this.isChecking = true;
        //   const res = await this.$axios.post(oneApi.contactVerify, data, {
        //     rewritePostBody: true,
        //   });
        //   this.isChecking = false;
        //   if (Number(res.code) !== 0) {
        //     this.$ElMessage({
        //       type: 'error',
        //       message: res.msg,
        //     });
        //     this.sendLoading = false;
        //     return;
        //   }
        // }
        callback();
      });
    },
    // 发送成功
    codeSendSuccess() {
      this.sendLoading = false;
      this.isSend = true;
      let valueCode = '';
      // 修改dialog文案使用
      valueCode = `+${this.formData.phoneAreaCode.value} ${this.formData.phoneOrEmail}`;
      this.$emit('titleChange', {
        isSend: this.isSend,
        valueCode,
      });
    },
    queryAccountCodeSendSuccess() {
      this.accountSendLoading = false;
      this.isQueryAccountCodeSend = true;
      this.$emit('changeAccountSend', this.isQueryAccountCodeSend);
    },
    backAccountCodeSend() {
      this.isQueryAccountCodeSend = false;
      this.$emit('changeAccountSend', this.isQueryAccountCodeSend);
    },
    codeSendError() {
      this.sendLoading = false;
    },
    queryAccountCodeSendError() {
      this.accountSendLoading = false;
    },
    updateType(type) {
      console.log('updateType', type);
      this.type = type;
    },
    reset(prop) {
      if (this.$refs.loginForm && this.$refs.loginForm.clearValidate) {
        this.$refs.loginForm.clearValidate(prop);
      }
    },
    accountReset(prop) {
      if (this.$refs.queryAccountForm && this.$refs.queryAccountForm.clearValidate) {
        this.$refs.queryAccountForm.clearValidate(prop);
      }
    },
    toSignUp(num) {
      // num 2 注册 3 登录
      this.hasRepeat = false
      this.hasRepeat2 = false
      this.$emit('toSignIn', num);
    },
    toSignLogin() {
      this.$emit('toSignLogin');
    },
    existAccountGoSendCode() {
      this.accountSendLoading = true
      console.log('existAccountSubmit', this.selectedAccountInfo);
      if (!this.selectedAccountInfo.phone && !this.selectedAccountInfo.email) {
        this.$ElMessage({
          type: 'error',
          message: '请选择账号',
        });
        this.accountSendLoading = false;
        return;
      }
      this.$refs.accountCode.sendCodeHanlder();
    },
    prepareLoginData() {
      const data = {
        phoneAreaCode: {
          value: '',
        },
        captcha: this.queryAccountData.captcha,
        selectedAccountType: this.selectedAccountType,
      };

      if (this.selectedAccountType === 'phone') {
        data.phoneOrEmail = this.selectedAccountInfo.phone;
        data.phoneAreaCode.value = this.selectedAccountInfo.countryCallingCode;
      } else {
        delete data.phoneAreaCode;
        data.phoneOrEmail = this.selectedAccountInfo.email;
      }

      return data;
    },
    existAccountLogin() {
      const data = this.prepareLoginData();
      data.originAccountIsempty = this.type !== this.selectedAccountType;
      // 如果原账号信息不为空
      if (this.selectedAccountInfo.phone && this.selectedAccountInfo.email) {
        data.originAccountIsempty = false;
      }
      this.$emit('changeType', this.selectedAccountType);
      this.$nextTick(() => {
        this.$emit('submit', data, true);
      });
    },
    originAccountLogin() {
      const data = this.prepareLoginData();
      this.$emit('changeType', this.selectedAccountType);
      this.$nextTick(() => {
        this.$emit('toCreateLogin', data);
      });
    },
    undateWithCode() {
      this.$emit('changeType', this.type);
      // 预校验选择信息
      const preData = this.prepareLoginData();
      // 用户输入信息 fromData
      const data = {
        preData,
        formData: this.formData,
      }
      this.$emit('updateRegisterInfo', data);
    },
    createLogin() {
      this.$emit('changeType', this.type);
      this.$emit('toCreateLogin', this.formData);
    },
    selectItem(index) {
      this.selectedIndex = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.arrow-button {
  width: 56px;
  height: 56px;
  background: var(--txt-color-link);
  border-radius: 28px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 16px;
  cursor: pointer;

  &:hover {
    background: var(--txt-color-link2);
  }

  .arrow-img {
    background: url("~assets/images/common/next-arrow.png") no-repeat;
    background-size: 100%;
    width: 24px;
    height: 24px;
  }
}

.arrow-disabled {
  opacity: 0.2;
}

.paddingTop8 {
  padding-top: 8px;
}

.tip {
  font-size: 12px;
  color: var(--txt-color-lv2);
  line-height: 14px;
  padding-bottom: 20px;
  text-align: center;
}
.has-repeat {
  font-size: 12px;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 500;
  color: #d44e4e;
  line-height: 15px;
  margin-top: -16px;
  margin-bottom: 10px;
  span {
    color: var(--txt-color-link);
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: var(--txt-color-link2);
    }
  }
}
.sign-up-n {
  font-size: 12px;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 500;
  color: var(--txt-color-lv2);
  line-height: 18px;
  margin-top: 20px;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  left: 24px;
  span {
    color: var(--txt-color-link);
    text-decoration: underline;
    &:hover {
      color: var(--txt-color-link2);
    }
  }
}
.exist-account-body {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin: 20px 0 40px 0;

  .exist-account-item {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex: 1 1 calc(50% - 6px);
    position: relative;
    cursor: pointer;
    height: 104px;
    padding: 14px 10px;
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.05);
    border-radius: 8px;
    border: 2px solid #fff;
    &.is-active {
      background: #FFF9EC;
      border: 2px solid var(--txt-color-link);
    }
    .item-body {
      display: flex;
      flex-direction: column;
    }
    .text {
      display: inline-block;
      margin-bottom: 16px;
      font-size: 14px;
      font-family: 'Montserrat-Medium', 'Montserrat', sans-serif;
      font-weight: 500;
      color: #222222;
      line-height: 18px;
    }
    .account {
      font-size: 20px;
      font-family: 'Montserrat-Bold', 'Montserrat', sans-serif;
      font-weight: bold;
      color: #FF9F0A;
      line-height: 24px;
    }
    .icon {
      position: absolute;
      width: 24px;
      height: 24px;
      bottom: -2px;
      right: -1px;
      color: var(--txt-color-link);
    }
  }
}
.create-operation {
  cursor: pointer;
  text-align: center;
  margin-top: 22px;
  font-size: 16px;
  font-family: 'Montserrat-Bold', 'Montserrat', sans-serif;
  font-weight: bold;
  color: rgba(34, 34, 34, 0.6);
  line-height: 16px;
}
.bind-account-body {
  text-align: center;
  .avatar {
    width: 80px;
    height: 80px;
    margin: 0 auto 20px;
    .img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .title {
    font-size: 20px;
    font-family: 'Montserrat-Bold', 'Montserrat', sans-serif;
    font-weight: bold;
    color: #222222;
    line-height: 24px;
  }
  .desc {
    margin-top: 10px;
    font-size: 14px;
    font-family: 'Montserrat-Regular', 'Montserrat', sans-serif;
    font-weight: 400;
    color: #7A7A7A;
    line-height: 18px;
  }
  .operation {
    margin-top: 36px;
    display: flex;
    justify-content: space-between;
    .bind-btn {
      text-align: center;
      cursor: pointer;
      width: 190px;
      height: 60px;
      border-radius: 30px;
      border: 1px solid #E6E6E6;
      font-size: 16px;
      font-family: 'Montserrat-Bold', 'Montserrat', sans-serif;
      font-weight: bold;
      line-height: 60px;
    }
    .left {
      background: #fff;
      color: rgba(34, 34, 34, 0.6);
    }
    .right {
      border: 1px solid var(--txt-color-link);
      background: var(--txt-color-link);
      color: #ffffff;
    }
  }
}
.update-container {
  .success-body {
    background: #FFF9EC;
    border-radius: 8px;
    margin-bottom: 36px;
    padding: 30px 16px 16px;
    .header {
      text-align: center;
      .icon {
        font-size: 80px;
        color: var(--txt-color-link);
      }
      .text {
        margin: 5px 0 30px;
        font-size: 20px;
        font-family: 'Montserrat-Bold', 'Montserrat', sans-serif;
        font-weight: bold;
        color: var(--txt-color-link);
        line-height: 24px;
      }
    }
    .info {
      background: #FFFFFF;
      border-radius: 8px;
      padding: 16px;
      .phone,
      .email {
        font-size: 16px;
        font-family: 'Montserrat-Medium', 'Montserrat', sans-serif;
        font-weight: 500;
        color: #222222;
        line-height: 18px;
      }
      .phone {
        margin-bottom: 8px;
      }
    }
  }
}
</style>
