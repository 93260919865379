export default {
  personalInfo: {
    registrationInfo: '註冊信息',
    contactInfo: '聯繫信息',
    externalBind: '外部綁定',
    notBind: '未綁定',
    bound: '已綁定',
    leaveAcademyTip: '你即將離開當前頁面，並跳轉到第三方頁面',
    unbindAccount: '解绑 {name} 賬號',
    afterUnbindTip: '解绑後，您將不能使用 {name} 進行登錄',
    bindSuccess: '帳號綁定成功',
    loadingText: '加載中，請稍候',
    tieToAccount: '綁定到 {name}',
    accountBind: '帳號綁定',
    moreSetting: '更多設置',
    registionToLogin: '註冊信息用於您的登錄',
  },
}
