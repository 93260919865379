<template>
  <div v-if="isShow">
    <div v-if="position == 1" class="op-bg">
      <div class="modal">
        <img
          v-lazy-load
          :class="['contentImg', skinType === 2 ? '' : 'cursorStyle']"
          v-lazy-load :data-src="webUrl"
          alt=""
          @click="linkJump"
        >
        <img
          v-lazy-load
          class="closeBtn"
          v-lazy-load data-src="./image/icon_close.png"
          alt=""
          @click="closeHandle"
        >
      </div>
    </div>
    <div v-else>
      <div class="rightBottom">
        <img
          v-lazy-load
          :class="['contentImg', skinType === 2 ? '' : 'cursorStyle']"
          v-lazy-load :data-src="webUrl"
          alt=""
          @click="linkJump"
        >
        <img
          v-lazy-load
          class="closeBtn"
          v-lazy-load data-src="./image/icon_close.png"
          alt=""
          @click="closeHandle"
        >
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 广告样式（一）
 */
import mixin from './mixin';
import trackReportMixin from '../Common/CommonMixin/trackReport';

export default {
  name: 'MarketComponents',
  mixins: [mixin, trackReportMixin],
};
</script>
<style lang="scss" scoped>
.op-bg {
  background: #22222260;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
}

.modal {
  width: 500px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .contentImg {
    width: 100%;
    margin-bottom: 16px;
    border-radius: 20px;
  }

  .cursorStyle {
    cursor: pointer;
  }

  .closeBtn {
    cursor: pointer;
    width: 32px;
    height: 32px;
  }
}

.rightBottom {
  width: 240px;
  position: fixed;
  bottom: 70px;
  right: 100px;
  z-index: 2000;

  .contentImg {
    width: 100%;
    cursor: pointer;
    border-radius: 12px;
  }

  .closeBtn {
    cursor: pointer;
    width: 24px;
    height: 24px;
    position: absolute;
    top: -1px;
    right: -1px;
  }
}
</style>
