export default {
  loginInfo: {
    findRelatedAcount: '당신의 계정일 가능성이 있는 계정 {num}개를 찾았습니다',
    existAccount: '이미 존재하는 계정',
    updateExistAccount: '기존 계정을 업데이트하시겠습니까?',
    updateRegisInfoExistAccount: '이 등록 정보를 기존 계정으로 업데이트하세요',
    MobileOrEmail: '전화번호 혹은 이메일',
    yes: '네',
    no: '아니요',
    success: '계정 업데이트 성공',
    mobile: '전화번호',
    email: '이메일',
    next: '다음',
    verifyTitle: '인증코드가 발송되였습니다',
    verifyContent: '아래에 입력하여 인증을 하세요',
  },
}
