// 引入md5
import md5 from 'crypto-js/md5'

/* eslint-disable no-undef */
const { verifyKey } = process.env
const timestampApi = 'https://one.thethinkacademy.com/v1/system/timestamp'
const verifyUrl = `https://www.recaptcha.net/recaptcha/enterprise.js?render=${verifyKey}`

const loadData = (result, loadingTime, context, successCount) => {
  console.log(result, loadingTime, context, successCount, 'loadData');
  context.Sensors.track('recaptcha_js_load', {
    is_successed: result === 'success' ? 1 : 0,
    loading_time: loadingTime,
    success_count: successCount,
  })
}
const getTokenStatus = (result, loadingTime, context, status) => {
  console.log(result, loadingTime, 'loadStatus')
  context.Sensors.track('recaptcha_interface_call', {
    is_successed: result === 'success' ? 1 : 0,
    loading_time: loadingTime,
    is_grecaptcha_defined: status || true,
  })
}

const getSignature = (httpBody, date) => {
  const timestamp = Math.floor(date / 1000)
  const data = JSON.stringify(httpBody)
  const { secret } = process.env
  // // md5 加密
  const md5Str = md5((md5(data).toString() + md5(secret + timestamp).toString())).toString()
  // base64 加密
  return btoa(md5Str + timestamp)
}

const getCustomToken = async ({ httpBody, timestampUrl = timestampApi }) => {
  let timestamp = Math.floor(Date.now() / 1000);
  const time = 1745805333
  try {
    const response = await fetch(timestampUrl, {
      method: 'POST',
      body: {},
    });
    const res = await response.json();
    if (res.code === 0) {
      timestamp = Math.floor(res.data / 1000);
    } else {
      throw new Error('get timestamp error');
    }
    console.log('res', res);
    console.log('timestamp', timestamp);
    console.log('time', time);
  } catch (error) {
    console.error(error);
  }
  const data = JSON.stringify(httpBody);
  console.log('httpBody', data);
  const testSecret = process.env.secret;
  console.log('secret', testSecret);
  const md5Str = md5((md5(data).toString() + md5(testSecret + time).toString())).toString()
  console.log('md5Str', md5Str);
  console.log(' btoa(md5Str + timestamp)', btoa(md5Str + time));
  return btoa(md5Str + time);
}

let successCount = 0;

function createScript() {
  let loadResult = ''
  const startTime = new Date().getTime();
  successCount += 1
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = verifyUrl
  script.async = true
  script.onload = () => {
    loadResult = 'success';
    const completionTime = new Date().getTime();
    loadData(loadResult, completionTime - startTime, this, successCount)
    successCount = 0;
  }
  script.onerror = () => {
    loadResult = 'error';
    const completionTime = new Date().getTime();
    loadData(loadResult, completionTime - startTime, this)
  }
  const timer = setTimeout(() => {
    if (loadResult !== 'success') {
      clearTimeout(timer);
      createScript();
    }
  }, 5000);
  document.getElementsByTagName('head')[0].appendChild(script)
}

function getToken() {
  const startTime = new Date().getTime()
  if (!grecaptcha) {
    getTokenStatus('error', 0, this, false);
    return Promise.resolve(''); // 返回一个解析为默认值的Promise
  }
  return new Promise((resolve) => {
    grecaptcha.enterprise.ready(async () => {
      try {
        const token = await grecaptcha.enterprise.execute(verifyKey, { action: 'leads' })
        if (token !== '') {
          const result = 'success'
          const endTime = new Date().getTime()
          getTokenStatus(result, endTime - startTime, this)
        }
        resolve(token)
      } catch (error) {
        const result = 'error'
        const endTime = new Date().getTime()
        getTokenStatus(result, endTime - startTime, this)
        resolve(''); // 在捕获到异常时，解析为默认值
      }
    })
  })
}

export {
  createScript, getToken, getSignature, getCustomToken,
}
