export default {
  loginInfo: {
    findRelatedAcount: 'アカウントを{num}つ見つかりました',
    existAccount: '既存アカウント',
    updateExistAccount: '既存のアカウントを更新しますか?',
    updateRegisInfoExistAccount: 'この登録情報を既存のアカウントに更新する',
    MobileOrEmail: '携帯番号かメール',
    yes: 'はい',
    no: 'いいえ',
    success: 'アカウント更新完了',
    mobile: '携帯',
    email: 'メール',
    next: '次へ',
    verifyTitle: '確認コードを送りました',
    verifyContent: 'を認証するために、コードを入力してください',
  },
}
